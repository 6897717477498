import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { AddBank } from "./AddBank";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { Loader } from "components/Loader";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { EditBank } from "./EditBank";
const keys = ["bank_name", "nick_name", "bank_branch", "bank_ifsc_code"];

export const PaymentBank = ({
  companyId,
  currentScreen,
  companyChange,
  setCompanyChange,
  editBankDetails,
  setEditBankDetails,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addNewBank, setAddNewBank] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCompanyDetails, setDeleteCompanyDetails] = useState({});
  const [bankDetailsStateData, setBankDetailsStateData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const {
    result: bankDetailsData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}source-bank/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyChange === true && currentScreen === "Payment Bank") {
      reCallApi(true);
      setCompanyChange(false);
    }
    setEditBankDetails(false);
    setAddNewBank(false);
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    setCompanyChange,
    setAddNewBank,
    setEditBankDetails,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  const updateDeleteBank = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}source-bank/${deleteCompanyDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ isdeleted: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
        setButtonDisabled(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (updateData && bankDetailsData?.length !== 0 && bankDetailsData) {
      setBankDetailsStateData(bankDetailsData);
      setUpdateData(false);
    }
  }, [bankDetailsData, updateData]);

  useEffect(() => {
    if (bankDetailsData?.length !== 0 || bankDetailsData?.length === 0) {
      const filteredResults = bankDetailsData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setBankDetailsStateData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, bankDetailsData]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={9}>
          {addNewBank || editBankDetails ? (
            <Mui.Stack direction="row">
              <BackArrow
                actionFunction={() => {
                  setEditBankDetails(false);
                  setAddNewBank(false);
                  dispatch(setGlobalSearchValue(""));
                }}
              />
              <Mui.Typography
                sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
              >
                {editBankDetails ? "Edit payment bank" : "Add payment bank"}
              </Mui.Typography>
            </Mui.Stack>
          ) : null}
        </Mui.Grid>
        {addNewBank || editBankDetails ? null : (
          <Mui.Grid container sx={{ paddingTop: 1 }}>
            <Mui.Grid xs={10}></Mui.Grid>
            <Mui.Grid
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => setAddNewBank(true)}
                actionName="Add Bank Account"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>
        )}
      </Mui.Grid>
      {addNewBank ? (
        <AddBank
          companyId={companyId}
          setAddNewBank={setAddNewBank}
          reCallApi={reCallApi}
        />
      ) : loading ? (
        <Loader />
      ) : editBankDetails ? (
        <EditBank
          bankDetails={deleteCompanyDetails}
          companyId={companyId}
          setEditBankDetails={setEditBankDetails}
          reCallApi={reCallApi}
        />
      ) : bankDetailsStateData?.length === 0 ? (
        <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
          No data found
        </Mui.Alert>
      ) : (
        <React.Fragment>
          <Mui.TableContainer
            sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Bank Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Nick Name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Account Number
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Branch
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    IFSC Code
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {bankDetailsStateData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((account, index) => (
                    <React.Fragment>
                      <Mui.TableRow>
                        <Mui.TableCell>{account.bank_name}</Mui.TableCell>
                        <Mui.TableCell>{account.nick_name}</Mui.TableCell>
                        <Mui.TableCell>
                          {account.bank_account_number}
                        </Mui.TableCell>
                        <Mui.TableCell>{account.bank_branch}</Mui.TableCell>
                        <Mui.TableCell>{account.bank_ifsc_code}</Mui.TableCell>
                        <Mui.TableCell>
                          <Mui.IconButton
                            onClick={() => {
                              setDeleteCompanyDetails(account);
                              setEditBankDetails(true);
                              dispatch(setGlobalSearchValue(""));
                            }}
                          >
                            <Edit />
                          </Mui.IconButton>
                          <Mui.IconButton
                            onClick={() => {
                              setDeleteCompanyDetails(account);
                              setOpenDialog(true);
                            }}
                          >
                            <Delete />
                          </Mui.IconButton>
                        </Mui.TableCell>
                      </Mui.TableRow>
                    </React.Fragment>
                  ))}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {bankDetailsStateData?.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={bankDetailsStateData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        </React.Fragment>
      )}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Bank Account"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {deleteCompanyDetails.bank_name}
          </span>{" "}
          bank account ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeleteBank()}
              actionName="Yes"
              typeName="button"
              disableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
