import * as React from "react";
import { useState, useRef, useEffect } from "react";
import LoginImage from "../../../assets/img/brand/loginHalf.png";
import LoginLogo from "../../../assets/img/brand/organflow 2.0 logo.png";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { Mail, Phone } from "@mui/icons-material";
import { showToast } from "services/utils/Status";
import { useSelector } from "react-redux";
// import { userManagementError } from "services/constants/ErrorMessages";
// import { MuiOtpInput } from "mui-one-time-password-input";
import sessionHandling from "services/utils/notificationUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const SendOTP = () => {
  const history = useHistory();
  const { domain } = useSelector((state) => state.tokenReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [sendOTP, setSendOTP] = useState(false);
  const [groupButtonActiveTap, setGroupButtonActiveTap] = useState(
    localStorage.getItem("LoginTag")
  );
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(90);
  const [otpVerification, setOtpVerification] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Change array length based on OTP length
  const inputRefs = useRef([]);
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let timer;
    if (isTimerActive && secondsRemaining > 0) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (secondsRemaining === 0) {
      setIsTimerActive(false);
      setSecondsRemaining(90);
    }
    return () => clearInterval(timer);
  }, [isTimerActive, secondsRemaining]);

  const onSubmit = async (data) => {
    setOtp(["", "", "", "", "", ""]);
    setIsTimerActive(true);
    let url =
      groupButtonActiveTap === "Email"
        ? `${domain}email/send/otp/?email=${data.email}`
        : `${domain}email/send/otp/?phone_no=${data.phoneno}`;
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          groupButtonActiveTap === "Email"
            ? "Sending the OTP verification email was successful."
            : "Sending the OTP verification phone number was successful."
        );
        setSendOTP(true);
        setIsLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      setIsLoading(false);
      showToast("error", error.message);
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      // Allow only numeric input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next input field if there is one
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      // Move focus to previous input field if there is one
      if (otp[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      }
    }
  };

  const verifyOTP = async () => {
    let otpNumber = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
    if (otpNumber.length === 6) {
      let url =
        groupButtonActiveTap === "PhoneNo"
          ? `${domain}email/send/otp/?phone_no=${getValues(
              "phoneno"
            )}&otp=${otpNumber}`
          : `${domain}email/send/otp/?email=${getValues(
              "email"
            )}&otp=${otpNumber}`;
      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          localStorage.setItem("resetPasswordLink", res.reset_password_url);
          history.push("/auth/otp/newPassword");
          reset();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        setOtpVerification(false);
        showToast("error", error.message);
      }
    } else {
      setOtpVerification(true);
    }
  };

  return (
    <>
      <Mui.Grid
        container
        style={{
          overflowY: "hidden",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <Mui.Grid item xs={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <img
              src={LoginImage}
              alt="Login"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
            <a href="https://stringconsultants.com/">
              <div
                style={{
                  position: "absolute",
                  left: "25%",
                  bottom: "0.2%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                © 2024 String Consultants. All Rights Reserved
              </div>
            </a>
          </Mui.Paper>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={5} lg={6}>
          <Mui.Paper style={{ height: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid
                container
                justifyContent="flex-end"
                sx={{ paddingTop: 2, paddingRight: 2 }}
              >
                <img
                  src={LoginLogo}
                  alt="LoginLogo"
                  style={{ height: "22%", width: "22%" }}
                />
              </Mui.Grid>
              <Mui.Grid
                container
                spacing={2}
                direction="column"
                dispay="flex"
                alignItems="center"
              >
                <Mui.Grid
                  item
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ minHeight: "14vh" }}
                ></Mui.Grid>
                {sendOTP ? (
                  <>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: 800,
                          fontSize: "38px",
                          paddingLeft: 6,
                        }}
                      >
                        Email OTP Verification
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          paddingLeft: 6,
                          color: themes.shadowText,
                        }}
                      >
                        Enter the verification code we just sent to your <br />{" "}
                        {groupButtonActiveTap === "Email"
                          ? `email ${getValues("email")}`
                          : `phone number ${getValues("phoneno")}`}
                      </Mui.Typography>
                    </Mui.Grid>
                  </>
                ) : (
                  <>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: 800,
                          fontSize: "38px",
                          paddingLeft: 6,
                        }}
                      >
                        Reset Password
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item>
                      {" "}
                      <Mui.Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          paddingLeft: 6,
                          color: themes.shadowText,
                        }}
                      >
                        Enter the{" "}
                        {groupButtonActiveTap === "Email"
                          ? "email address"
                          : "phone number"}{" "}
                        associated with your <br /> account , and We'll send a
                        password reset code <br />{" "}
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid item xs={12}>
                      <Mui.ButtonGroup
                        size="large"
                        aria-label="Large button group"
                        sx={{ border: `1px solid ${themes.loginImage}` }}
                      >
                        <Mui.Button
                          variant={
                            groupButtonActiveTap === "Email"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setSendOTP(false);
                            reset();
                            setGroupButtonActiveTap("Email");
                            localStorage.setItem("LoginTag", "Email");
                            setOtp(["", "", "", "", "", ""]);
                          }}
                          sx={{
                            background:
                              groupButtonActiveTap === "Email"
                                ? themes.loginImage
                                : "outlined",
                            textTransform: "capitalize",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              background:
                                groupButtonActiveTap === "Email"
                                  ? themes.loginImage
                                  : "outlined",
                            },
                            color:
                              groupButtonActiveTap === "Email"
                                ? themes.whiteColor
                                : themes.headLine,
                            width: 203,
                          }}
                        >
                          Email
                        </Mui.Button>
                        <Mui.Button
                          variant={
                            groupButtonActiveTap === "PhoneNo"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => {
                            setSendOTP(false);
                            reset();
                            setGroupButtonActiveTap("PhoneNo");
                            localStorage.setItem("LoginTag", "PhoneNo");
                            setOtp(["", "", "", "", "", ""]);
                          }}
                          sx={{
                            background:
                              groupButtonActiveTap === "PhoneNo"
                                ? themes.loginImage
                                : "outlined",
                            textTransform: "capitalize",
                            border: "none",
                            "&:hover": {
                              border: "none",
                              background:
                                groupButtonActiveTap === "PhoneNo"
                                  ? themes.loginImage
                                  : "outlined",
                            },
                            color:
                              groupButtonActiveTap === "Email"
                                ? themes.headLine
                                : themes.whiteColor,
                            width: 203,
                          }}
                        >
                          Phone No
                        </Mui.Button>
                      </Mui.ButtonGroup>
                    </Mui.Grid>
                  </>
                )}
                <Mui.Grid item xs={12} sx={{ marginTop: 2 }}>
                  {groupButtonActiveTap === "Email" && !sendOTP ? (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{ fontSize: 20, paddingRight: 43, fontWeight: 600 }}
                      >
                        Email
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          minWidth: 407,
                        }}
                        size="medium"
                        type="text"
                        placeholder="Please enter e-mail"
                        {...register("email", {
                          required:
                            groupButtonActiveTap === "Email"
                              ? "Email is required"
                              : false,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Badge color="secondary" badgeContent={0}>
                                <Mail color="primary" />
                              </Mui.Badge>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </React.Fragment>
                  ) : !sendOTP ? (
                    <React.Fragment>
                      <Mui.Typography
                        sx={{ fontSize: 20, paddingRight: 35, fontWeight: 600 }}
                      >
                        Phone No
                      </Mui.Typography>
                      <Mui.TextField
                        sx={{
                          minWidth: 407,
                        }}
                        size="medium"
                        type="text"
                        placeholder="Please enter phone number"
                        {...register("phoneno", {
                          required:
                            groupButtonActiveTap === "Email"
                              ? false
                              : "Phone No is required",
                          minLength: {
                            value: 10,
                            message: "Minimum length is 10 digits",
                          },
                          maxLength: {
                            value: 15,
                            message: "Maximum length is 15 digits",
                          },
                        })}
                        error={errors?.phoneno}
                        helperText={errors?.phoneno?.message}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Mui.Badge color="secondary" badgeContent={0}>
                                <Phone color="primary" />
                              </Mui.Badge>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </React.Fragment>
                  ) : null}
                  {!sendOTP ? (
                    <Mui.Grid
                      sx={{
                        paddingTop: 1,
                        maxWidth: 407,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <a className="text-dark" href="/auth/login">
                        <Mui.Typography
                          fontSize={15}
                          style={{ fontWeight: "bold", paddingLeft: 270 }}
                        >
                          Back to login ?
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                  ) : null}
                  {sendOTP ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 5,
                        }}
                      >
                        {otp.map((_, index) => (
                          <input
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)} // Store refs to all inputs
                            type="text"
                            maxLength="1" // Ensure only one character is entered
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            style={{
                              width: "50px",
                              height: "50px",
                              textAlign: "center",
                              fontSize: "25px",
                              margin: "0 5px",
                              borderWidth: 2,
                              border:
                                otp[index] === "" && otpVerification
                                  ? `1px solid ${themes.txtErrorColor}`
                                  : false,
                            }}
                          />
                        ))}
                      </div>
                      <div style={{ paddingTop: 5 }}>
                        {isTimerActive ? (
                          <Mui.Typography
                            style={{
                              color: themes.shadowText,
                              textAlign: "right",
                              paddingRight: 25,
                              textDecorationLine: isTimerActive
                                ? "none"
                                : "underline",
                              // cursor: isTimerActive ? "none" : "pointer",
                            }}
                            onClick={{}}
                          >
                            {isTimerActive
                              ? `Resend in ${secondsRemaining}s`
                              : ""}
                          </Mui.Typography>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              paddingRight: 20,
                            }}
                          >
                            <Mui.Button
                              sx={{ textTransform: "none" }}
                              type={"submit"}
                            >
                              Resend ?
                            </Mui.Button>
                          </div>
                        )}
                      </div>
                    </>
                  ) : // <Mui.Grid item xs={12} sx={{ marginTop: 2, width: 407 }}>
                  //   <Controller
                  //     name="otp"
                  //     control={control}
                  //     rules={{ validate: (value) => value?.length === 6 }}
                  //     render={({
                  //       field,
                  //       field: { onChange, value },
                  //       fieldState,
                  //     }) => (
                  //       <Mui.Box>
                  //         <MuiOtpInput
                  //           sx={{
                  //             gap: 1,
                  //           }}
                  //           {...field}
                  //           length={6}
                  //           inputStyle={{
                  //             style: { border: "apx solid red" },
                  //           }}
                  //         />
                  //         {fieldState.invalid ? (
                  //           <Mui.Typography
                  //             sx={{
                  //               color: themes.txtErrorColor,
                  //               paddingTop: 2,
                  //             }}
                  //           >
                  //             OTP invalid
                  //           </Mui.Typography>
                  //         ) : null}
                  //       </Mui.Box>
                  //     )}
                  //   />
                  // </Mui.Grid>
                  null}
                  <Mui.Grid
                    item
                    xs={12}
                    sx={{
                      marginTop: 5,
                      minWidth: 407,
                      marginRight: "auto",
                    }}
                  >
                    <Mui.Grid justifyContent="center">
                      {sendOTP ? (
                        <Mui.Button
                          variant="contained"
                          type={"button"}
                          size="large"
                          sx={{
                            minWidth: 407,
                            borderRadius: 3,
                            backgroundColor: themes.loginImage,
                            "&:hover": {
                              border: "none",
                              background: themes.loginImage,
                            },
                            color: themes.whiteColor,
                            textTransform: "none",
                            fontSize: 20,
                          }}
                          onClick={() => {
                            verifyOTP();
                          }}
                        >
                          {isLoading ? (
                            <Mui.CircularProgress size={24} color="inherit" />
                          ) : (
                            "Verify"
                          )}
                        </Mui.Button>
                      ) : (
                        <Mui.Button
                          variant="contained"
                          type={sendOTP ? "button" : "submit"}
                          size="large"
                          sx={{
                            minWidth: 407,
                            borderRadius: 3,
                            backgroundColor: themes.loginImage,
                            "&:hover": {
                              border: "none",
                              background: themes.loginImage,
                            },
                            color: themes.whiteColor,
                            textTransform: "none",
                            fontSize: 20,
                          }}
                        >
                          {isLoading ? (
                            <Mui.CircularProgress size={24} color="inherit" />
                          ) : (
                            "Send OTP"
                          )}
                        </Mui.Button>
                      )}
                    </Mui.Grid>
                  </Mui.Grid>

                  {/* <Mui.Grid
                    container
                    xs={12}
                    sx={{ paddingTop: 4, marginTop: 3 }}
                  >
                    <Mui.Grid
                      sx={{
                        borderRight: "3px solid green",
                      }}
                    ></Mui.Grid>
                    <Mui.Grid item xs={6}>
                      <a href="https://organflow.app/about/">
                        <Mui.Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          About us
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                    <Mui.Grid item xs={5.9}>
                      <a href="https://organflow.app/privacy-policy/">
                        <Mui.Typography
                          sx={{
                            fontWeight: 600,
                            borderRight: "3px solid green",
                            textAlign: "center",
                          }}
                        >
                          Privacy Policy
                        </Mui.Typography>
                      </a>
                    </Mui.Grid>
                  </Mui.Grid> */}
                </Mui.Grid>
              </Mui.Grid>
            </form>
          </Mui.Paper>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
