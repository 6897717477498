import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
import { showToast } from "services/utils/Status";
import { DialogBox } from "../DialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import sessionHandling from "services/utils/notificationUtils";
const keys = [
  "status",
  "user_name",
  "amount",
  "repayment_date",
  "advance_type",
  "description",
  "reason",
];
export const Approve = ({
  departmentId,
  departmentChange,
  setDepartmentChange,
  overTimeScreen,
  setReloadData,
}) => {
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectedReasonValidation, setRejectedReasonValidation] = useState("");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expenseSalary, setExpenseSalary] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const {
    result: advanceDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}advances/?user_id=${userDetails.id}&status=Pending&department_id=${departmentId}`
  );

  useEffect(() => {
    if (departmentChange === true && overTimeScreen === "Approve") {
      reCallApi(true);
      setDepartmentChange(false);
    }
  }, [departmentChange, overTimeScreen, reCallApi, setDepartmentChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteCancel = () => {
    setRejectReason("");
    setOpenRejectDialog(false);
    setRejectedReasonValidation("");
  };

  const onSubmit = async () => {
    if (rejectReason.trim() === "") {
      setRejectedReasonValidation("Reason");
      return;
    } else if (rejectReason.length < 5) {
      setRejectedReasonValidation("Less than 5 character");
      return;
    }

    try {
      await handleReject();
      setOpenRejectDialog(false);
      setRejectedReasonValidation("");
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleApprove = async (item) => {
    try {
      const response = await fetch(`${domain}advances/${item.id}/approve/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", res.status);
        reCallApi(true);
        setReloadData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleReject = async () => {
    if (!selectedItem) return;
    try {
      const response = await fetch(
        `${domain}advances/${selectedItem.id}/reject/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            reason: rejectReason,
          }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", res.status);
        reCallApi(true);
        setReloadData(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    if (updateData && advanceDetails?.length !== 0 && advanceDetails) {
      setExpenseSalary(advanceDetails);
      setUpdateData(false);
    }
  }, [advanceDetails, updateData]);

  useEffect(() => {
    if (advanceDetails?.length !== 0 || advanceDetails?.length === 0) {
      const filteredResults = advanceDetails?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setExpenseSalary(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, advanceDetails]);

  return loading ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
        className="w-96 border rounded"
      >
        <Mui.Table className="border">
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
            }}
          >
            <Mui.TableRow>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Date</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Employee Name</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">
                  Advance Amount
                </Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Expense Type</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Description</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold" sx={{ textAlign: "center" }}>
                  Actions
                </Mui.Typography>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>

          <Mui.TableBody>
            {expenseSalary?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert sx={{ paddingTop: 1 }} severity="info">
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              expenseSalary
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <Mui.TableRow>
                    <Mui.TableCell>{item?.repayment_date}</Mui.TableCell>
                    <Mui.TableCell>{item?.user_name}</Mui.TableCell>
                    <Mui.TableCell>{item?.amount}</Mui.TableCell>
                    <Mui.TableCell>{item?.expesne_type}</Mui.TableCell>
                    <Mui.TableCell>{item?.description}</Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                      >
                        <Mui.Button
                          onClick={() => {
                            handleApprove(item);
                          }}
                          sx={{
                            background: themes.primaryIndicator,
                            color: themes.headLine,
                            textTransform: "capitalize",
                            width: 80,
                            "&:hover": {
                              backgroundColor: themes.primaryIndicator,
                            },
                          }}
                          size="small"
                        >
                          Approve
                        </Mui.Button>
                        <Mui.Button
                          onClick={() => {
                            setOpenRejectDialog(true);
                            setSelectedItem(item);
                          }}
                          sx={{
                            background: themes.redColor,
                            color: themes.headLine,
                            textTransform: "capitalize",
                            width: 80,
                            "&:hover": {
                              backgroundColor: themes.redColor,
                            },
                          }}
                          size="small"
                        >
                          Reject
                        </Mui.Button>
                      </Mui.Stack>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
        {expenseSalary.length > 10 && (
          <Mui.TablePagination
            className="custom-pagination"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={expenseSalary.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Mui.TableContainer>

      <DialogBox
        open={openRejectDialog}
        onClose={handleDeleteCancel}
        title="Reason for Rejection"
      >
        <Mui.TextField
          multiline
          minRows={3}
          sx={{
            marginTop: 1,
            width: "95%",
            marginLeft: 2,
            marginRight: "auto",
          }}
          placeholder="please enter reason of rejection"
          onChange={(e) => setRejectReason(e.target.value)}
        />
        {rejectedReasonValidation === "Reason" ? (
          <Mui.Typography color="red" sx={{ paddingLeft: 3 }}>
            Please enter the rejected reason
          </Mui.Typography>
        ) : null}
        {rejectedReasonValidation === "Less than 5 character" ? (
          <Mui.Typography color="red" sx={{ paddingLeft: 3 }}>
            Minium 5 character required
          </Mui.Typography>
        ) : null}

        <Mui.Grid container sx={{ paddingTop: 1 }}>
          <Mui.Grid xs={8}></Mui.Grid>
          <Mui.Grid
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <CustomButton
              actionFuntion={handleDeleteCancel}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={onSubmit}
              actionName="Yes"
              typeName="submit"
            />
          </Mui.Grid>
        </Mui.Grid>
      </DialogBox>
    </React.Fragment>
  );
};
