import React, { useState } from "react";
import * as Mui from "@mui/material";
import dayjs from "dayjs";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { rgxWithDot, onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { atleastOneCharacterWithSpace } from "services/constants/patterns";
import { minimum3Characters } from "services/constants/ErrorMessages";

export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const { result: users } = useFetch(
    `${domain}list/employee?user_id=${userDetails.id}`
  );
  const today = dayjs().format("YYYY-MM-DD");
  const [descriptions, setDescriptions] = useState("");
  const [startDate, setStartDate] = useState(today);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [loanAmount, setLoanAmount] = useState(0);
  const [emiAmount, setEmiAmount] = useState(0);
  const [loanInterestPercentage, setLoanInterestPercentage] = useState(0);
  const [tenureMonthCount, setTenureMonthCount] = useState(0);
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}loans/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          action: "Approved",
          user: data.user_id,
          principal_amount: data.loan_amount,
          interest_rate: data.interest,
          tenure_months: data.tenure,
          repayment_start_date: data.from_date,
          monthly_installment: data.emi_amount,
          description: data.descriptions,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          `You created ${data.userName} loan on ${data.from_date
            .split("-")
            .reverse()
            .join("-")} and it has been approved.`
        );
        clearAllData();
        setStartDate(today);
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const clearAllData = () => {
    setDescriptions("");
    setSelectedUserName("");
    setLoanAmount(0);
    setEmiAmount(0);
    setStartDate(today);
    setLoanInterestPercentage(0);
    setTenureMonthCount(0);
    setStartDate("");
    reset();
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userName", data?.label);
      setValue("user_id", data?.user_id);
      setSelectedUserName(data?.label);
    } else {
      setValue("userName", null);
      setSelectedUserName(null);
    }
  };

  const updateLoanAmount = (event) => {
    if (
      event.target.value.length <= 8 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("loan_amount", event.target.value);
      setLoanAmount(event.target.value);
      calculateEMIAmount();
    }
  };

  const updateDesciptions = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("descriptions", event.target.value);
      setDescriptions(event.target.value);
    }
  };

  const updateInterestPercentage = (event) => {
    if (rgxWithDot.test(event.target.value) && event.target.value.length <= 6) {
      setValue("interest", event.target.value);
      setLoanInterestPercentage(event.target.value);
      calculateEMIAmount();
    } else {
      event.preventDefault();
    }
  };

  const updateEMIAmount = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setValue("emi_amount", event.target.value);
      setEmiAmount(event.target.value);
      calculateEMIAmount();
    }
  };

  const updateTenureMonth = (event) => {
    if (
      onlyNumberRegex.test(event.target.value) &&
      event.target.value.length <= 2
    ) {
      setValue("tenure", event.target.value);
      setTenureMonthCount(event.target.value);
      calculateEMIAmount();
    } else {
      event.preventDefault();
    }
  };

  const calculateEMIAmount = async () => {
    let tenure = parseInt(getValues("tenure"));
    let principalAmount = parseInt(getValues("loan_amount"));
    let interest = parseFloat(getValues("interest")).toFixed(2);
    if (tenure && principalAmount && interest) {
      let R = parseFloat(interest) / 12 / 100;
      let PR = principalAmount * R;
      let EMI = (PR * Math.pow(1 + R, tenure)) / [Math.pow(1 + R, tenure) - 1];
      setValue("emi_amount", EMI ? Math.round(EMI) : 0);
      setEmiAmount(EMI ? Math.round(EMI) : 0);
    }
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }

    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid container>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Employee Name
          </Mui.Typography>
          <CustomAutoComplete
            dataList={users?.map((obj) => ({
              ...obj,
              label: obj.user_name,
            }))}
            selectedValue={selectedUserName}
            rules={{
              ...register("userName", {
                required: "User name is required",
              }),
            }}
            updateDataFunction={updateUserName}
            placeHolder="Please select the name"
            errorMessage={
              errors?.userName?.message ? errors?.userName?.message : ""
            }
            textBoxError={errors.userName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Loan Amount
          </Mui.Typography>
          <CustomTextBox
            selectedValue={loanAmount}
            rules={{
              ...register("loan_amount", {
                required: "Loan amount is required",
              }),
            }}
            updateDataFunction={updateLoanAmount}
            placeHolder="Enter loan amount"
            errorMessage={
              errors?.loan_amount?.message ? errors?.loan_amount?.message : ""
            }
            textBoxError={errors.loan_amount ? true : false}
          />
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container>
        <Mui.Grid item xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Tenure(Month)
          </Mui.Typography>
          <CustomTextBox
            selectedValue={tenureMonthCount}
            rules={{
              ...register("tenure", {
                required: "Tenure month is required",
                pattern: {
                  value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                },
                validate: {
                  checkAmount: (fieldValue) => {
                    const value = parseFloat(fieldValue);
                    if (isNaN(value) || value < 0 || value > 100) {
                      return "Tenure month must be less then 100";
                    } else {
                      return true;
                    }
                  },
                },
              }),
            }}
            updateDataFunction={updateTenureMonth}
            placeHolder="Enter the tenure month"
            errorMessage={
              errors?.tenure?.message ? errors?.tenure?.message : ""
            }
            textBoxError={errors.tenure ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid item xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Interest
          </Mui.Typography>
          <CustomTextBox
            selectedValue={loanInterestPercentage}
            rules={{
              ...register("interest", {
                required: "Interest percentange is required",
                pattern: {
                  value: /^0|[1-9]\d*(\.\d+)?$|0\.\d+$/,
                },
                validate: {
                  checkAmount: (fieldValue) => {
                    const value = parseFloat(fieldValue);
                    if (isNaN(value) || value < 0 || value > 100) {
                      return "Interest must be less then 100 %";
                    } else {
                      return true;
                    }
                  },
                },
              }),
            }}
            updateDataFunction={updateInterestPercentage}
            placeHolder="Enter the loan interest percentage"
            errorMessage={
              errors?.interest?.message ? errors?.interest?.message : ""
            }
            textBoxError={errors.interest ? true : false}
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              padding: 1,
              fontSize: 19,
              color: themes.headLine,
            }}
          >
            Payment Date
          </Mui.Typography>
          <Mui.TextField
            fullWidth
            size="small"
            type="date"
            id="from_date"
            value={startDate}
            {...register("from_date", {
              required: "Date is required.",
              validate: validateStartDate,
            })}
            error={!!errors.from_date}
            helperText={errors.from_date && errors.from_date.message}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
          />
        </Mui.Grid>
        <Mui.Grid item xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            EMI Amount
          </Mui.Typography>
          <CustomTextBox
            selectedValue={emiAmount}
            rules={{
              ...register("emi_amount", {
                required: "EMI amount is required",
              }),
            }}
            updateDataFunction={updateEMIAmount}
            placeHolder="Enter EMI amount"
            errorMessage={
              errors?.emi_amount?.message ? errors?.emi_amount?.message : ""
            }
            textBoxError={errors.emi_amount ? true : false}
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Typography
          sx={{
            fontSize: 20,
            color: themes.headLine,
            padding: 1,
          }}
        >
          Description
        </Mui.Typography>
        <CustomTextBox
          type="multiline"
          selectedValue={descriptions}
          rules={{
            ...register("descriptions", {
              required: "Descriptions is required",
              validate: {
                minLengthNoSpaces: (value) => {
                  if (value?.trim().length < 3 && value !== "") {
                    return minimum3Characters;
                  } else if (value?.trim().length > 255 && value !== "") {
                    return "Maximum length is 255 characters";
                  } else {
                    return true;
                  }
                },
                atleastOneCharacterWithSpaceReg: (value) => {
                  if (
                    atleastOneCharacterWithSpace.test(value) ||
                    value === ""
                  ) {
                    return true;
                  } else {
                    return "Atleast one alphabet required";
                  }
                },
              },
            }),
          }}
          updateDataFunction={updateDesciptions}
          placeHolder="Enter descriptions"
          errorMessage={
            errors?.descriptions?.message ? errors?.descriptions?.message : ""
          }
          textBoxError={errors.descriptions ? true : false}
        />
      </Mui.Grid>
      <Mui.Grid
        container
        spacing={1}
        sx={{
          paddingTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        <Mui.Grid item>
          <CustomButton
            actionName="Clear"
            typeName="button"
            actionFuntion={clearAllData}
          />
        </Mui.Grid>
        <Mui.Grid item>
          <CustomButton
            actionName="Apply"
            typeName="submit"
            disableAction={buttonLoader}
          />
        </Mui.Grid>
      </Mui.Grid>
    </form>
  );
};
