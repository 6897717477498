import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";
const keys = [
  "principal_amount",
  "tenure_months",
  "monthly_installment",
  "description",
  "status",
];

export const History = ({
  startDate,
  endDate,
  setLeaveChange,
  leaveChange,
}) => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRow, setExpandedRow] = useState(null);
  const [expandDetails, setExpandDetails] = useState(null);
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);

  const {
    result: loanDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}loans/?selected_user_id=${userDetails.id}&start_date=${startDate}&end_date=${endDate}`
  );

  useEffect(() => {
    if (leaveChange === true) {
      reCallApi(true);
      setLeaveChange(false);
    }
  }, [leaveChange, reCallApi, setLeaveChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    if (updateData && loanDetails?.length !== 0 && loanDetails) {
      setLoanData(loanDetails);
      setUpdateData(false);
    }
  }, [loanDetails, updateData]);

  useEffect(() => {
    const filteredResults = loanDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLoanData(filteredResults);
    setPage(0);
  }, [globalSearchValue, loanDetails]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          padding: 0.5,
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
        className="w-96 border rounded"
      >
        <Mui.Table className="border">
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
              position: "sticky",
              top: -1,
              zIndex: 10,
              "& .MuiTableCell-root": {
                height: "15px",
                padding: "6px 35px",
              },
            }}
          >
            <Mui.TableRow>
              <Mui.TableCell></Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Loan Amount</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Tenure</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">EMI Amount</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Description</Mui.Typography>
              </Mui.TableCell>
              <Mui.TableCell>
                <Mui.Typography fontWeight="bold">Status</Mui.Typography>
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {loanData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <>
                  <Mui.TableRow
                    sx={{
                      "& .MuiTableCell-root": {
                        height: "15px",
                        padding: "6px 35px",
                      },
                    }}
                  >
                    <Mui.TableCell>
                      {expandedRow === item.id ? (
                        <Mui.IconButton
                          onClick={() => {
                            setExpandedRow(null);
                          }}
                        >
                          <KeyboardArrowDownIcon
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </Mui.IconButton>
                      ) : (
                        <Mui.IconButton
                          onClick={() => {
                            setExpandDetails(item.monthly_payments);
                            setTimeout(function timeout() {
                              handleRowClick(item.id);
                            }, 100);
                          }}
                        >
                          <KeyboardArrowRightIcon
                            style={{
                              color: "black",
                              fontWeight: "bold",
                            }}
                          />
                        </Mui.IconButton>
                      )}
                    </Mui.TableCell>

                    <Mui.TableCell>{item?.principal_amount}</Mui.TableCell>
                    <Mui.TableCell>{item?.tenure_months}</Mui.TableCell>
                    <Mui.TableCell>
                      {item?.monthly_installment
                        ? item?.monthly_installment
                        : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>{item?.description}</Mui.TableCell>
                    <Mui.TableCell>{item?.status}</Mui.TableCell>
                  </Mui.TableRow>
                  {expandedRow === item.id && item.status !== "Rejected" && (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={12}>
                        <Mui.TableContainer>
                          <Mui.Table className="border">
                            <Mui.TableHead
                              sx={{
                                backgroundColor: themes.primary,
                                position: "sticky",
                                top: -1,
                                zIndex: 10,
                                "& .MuiTableCell-root": {
                                  height: "15px",
                                  padding: "6px 35px",
                                },
                              }}
                            >
                              <Mui.TableRow>
                                <Mui.TableCell>
                                  <Mui.Typography fontWeight="bold">
                                    Payment Month
                                  </Mui.Typography>
                                </Mui.TableCell>
                                <Mui.TableCell>
                                  <Mui.Typography fontWeight="bold">
                                    Paid Date
                                  </Mui.Typography>
                                </Mui.TableCell>
                                <Mui.TableCell>
                                  <Mui.Typography fontWeight="bold">
                                    Total Amount
                                  </Mui.Typography>
                                </Mui.TableCell>
                                <Mui.TableCell>
                                  <Mui.Typography fontWeight="bold">
                                    Status
                                  </Mui.Typography>
                                </Mui.TableCell>
                              </Mui.TableRow>
                            </Mui.TableHead>
                            <Mui.TableBody>
                              {expandDetails.length === 0 &&
                              item.status !== "Rejected" ? (
                                <Mui.TableRow>
                                  <Mui.TableCell colSpan={12}>
                                    <Mui.Alert
                                      severity="info"
                                      sx={{ paddingTop: 1 }}
                                    >
                                      There is no data available
                                    </Mui.Alert>
                                  </Mui.TableCell>
                                </Mui.TableRow>
                              ) : (
                                expandDetails.map((item) => (
                                  <Mui.TableRow
                                    sx={{
                                      "& .MuiTableCell-root": {
                                        height: "15px",
                                        padding: "6px 35px",
                                      },
                                    }}
                                  >
                                    <Mui.TableCell>
                                      {item?.payment_month}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      {item?.paid_date
                                        ? item?.paid_date
                                        : "N/A"}
                                    </Mui.TableCell>
                                    <Mui.TableCell>
                                      {" "}
                                      {item?.amount}
                                    </Mui.TableCell>
                                    <Mui.TableCell>{item.status}</Mui.TableCell>
                                  </Mui.TableRow>
                                ))
                              )}
                            </Mui.TableBody>
                          </Mui.Table>
                        </Mui.TableContainer>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  )}

                  {expandedRow === item.id && item.status === "Rejected" && (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={9}>
                        <Mui.TableContainer
                          sx={{
                            marginTop: 0.5,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "100%px",
                          }}
                          className="w-96 border rounded"
                        >
                          <Mui.Table className="border">
                            <Mui.Typography
                              variant="body1"
                              gutterBottom
                              sx={{ marginLeft: 1, marginTop: 0.5 }}
                            >
                              <strong>Reason for Rejection :</strong>{" "}
                              <Mui.Typography
                                fontSize={15}
                                color="textSecondary"
                                sx={{ marginLeft: 13 }}
                              >
                                {item.reason}
                              </Mui.Typography>
                            </Mui.Typography>
                          </Mui.Table>
                        </Mui.TableContainer>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  )}
                </>
              ))}
          </Mui.TableBody>
        </Mui.Table>
        {loanData?.length > 0 ? null : (
          <div style={{ paddingTop: 8 }}>
            <Mui.Alert severity="info">There is no data available</Mui.Alert>
          </div>
        )}
      </Mui.TableContainer>

      {loanData?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={loanData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
