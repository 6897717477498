import React, { useContext, useState, useEffect, useCallback } from "react";
import * as Mui from "@mui/material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { themes } from "services/constants";
import { ShiftAssignContext } from "../ShiftManageContext/ShiftAssignContext";
import { useForm, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const cycleTypes = [
  { id: 1, type: "Weekly" },
  { id: 2, type: "Monthly" },
];

const AddShiftAssign = ({ updateShowAddShiftAsgn, shftAsgnPro }) => {
  const dispatch = useDispatch();
  const { domain, token, setReldSftAsgnApi, allSftCategoryDet } =
    useContext(ShiftAssignContext);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    trigger,
    formState: { errors },
  } = useForm();
  const [sftAsgnCatName, setSftAsgnCatName] = useState("");
  const [sftAsgnCatId, setSftAsgnCatId] = useState("");
  const [sftAsgnCatTotalShifts, setSftAsgnCatTotalShifts] = useState([]);
  const [sftAsgnCatTotalShiftGrps, setSftAsgnCatTotalShiftGrps] = useState([]);
  const [sftAsgnCycType, setSftAsgnCycType] = useState("Weekly");
  const [sftAsgnCycStartDate, setSftAsgnCycStartDate] = useState(new Date());
  const [sftAsgnCycStartDorM, setSftAsgnCycStartDorM] = useState("");
  const [sftAsgnCycNumCount, setSftAsgnCycNumCount] = useState(1);
  const [sftAsgnCycRecurType, setSftAsgnCycRecurType] = useState("recurtype1");
  const [sftAsgnCycRecurCount, setSftAsgnCycRecurCount] = useState(1);
  const [sftAsgnCycRecurUntil, setSftAsgnCycRecurUntil] = useState(new Date());
  const [sftAsgnSftGrpData, setSftAsgnSftGrpData] = useState([]);
  const [sftAsgnSftGrpCombData, setsftAsgnSftGrpCombData] = useState([]);
  const [sftAsgnErr, setSftAsgnErr] = useState("");
  const [dataValidErr, setDataValidErr] = useState("");
  const [sftAsgnCycLabData, setSftAsgnCycLabData] = useState([]);
  const [sftAsgnCycLab, setSftAsgnCycLab] = useState("");
  const [sftAsgnCycCalenDat, setSftAsgnCycCalenDat] = useState([]);

  useEffect(() => {
    setValue("shiftCycleCount", "1");
    setValue("occurrencesCount", "1");
  }, [setValue]);

  const getSftCatShiftDetails = useCallback(
    async (sftId) => {
      try {
        const response = await fetch(
          `${domain}shift-category-based-shift/?shift_category_id=${sftId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.shift_name,
          }));
          setSftAsgnCatTotalShifts([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  useEffect(() => {
    const cycleData = sftAsgnSftGrpData?.map((cycle) => {
      const cycleKey = Object.keys(cycle)[0];
      return { label: cycleKey, value: cycleKey };
    });
    if (cycleData.length > 0) {
      setSftAsgnCycLabData(cycleData);
      setSftAsgnCycLab(cycleData[0].label);
    }
  }, [sftAsgnSftGrpData]);

  const validateCount = useCallback(() => {
    if (
      isNaN(parseInt(sftAsgnCycRecurCount)) ||
      parseInt(sftAsgnCycRecurCount) < 1
    ) {
      return "Total period must be a positive number";
    }
    if (sftAsgnCycType === "Weekly" && parseInt(sftAsgnCycRecurCount) > 52) {
      return "Total period must be between 1 and 52";
    }
    if (sftAsgnCycType === "Monthly" && parseInt(sftAsgnCycRecurCount) > 12) {
      return "Total period must be between 1 and 12";
    }
    if (
      !moment(sftAsgnCycStartDate)
        .startOf("day")
        .isSameOrAfter(moment(new Date()).startOf("day"))
    ) {
      return "Start date set to be only from current date";
    }
    if (
      !moment(sftAsgnCycRecurUntil)
        .startOf("day")
        .isSameOrAfter(moment(new Date()).startOf("day"))
    ) {
      return "Until date set to be after start date";
    }
    return "";
  }, [
    sftAsgnCycRecurCount,
    sftAsgnCycType,
    sftAsgnCycStartDate,
    sftAsgnCycRecurUntil,
  ]);

  const getCycleCalendarData = useCallback(async () => {
    if (sftAsgnCycLab !== "" && sftAsgnSftGrpData.length > 0) {
      const errorMessage = validateCount();
      if (!errorMessage && !isNaN(parseInt(sftAsgnCycNumCount))) {
        const selectedCycle = sftAsgnSftGrpData.find(
          (cycle) => Object.keys(cycle)[0] === sftAsgnCycLab
        );

        if (!selectedCycle) {
          return [];
        }

        const formattedData = selectedCycle[sftAsgnCycLab].shifts.map(
          (shift) => ({
            shift_id: shift.shift_id,
            group_id: shift.groups.map((group) => group.id),
          })
        );

        const params = new URLSearchParams({
          shift_cycle: sftAsgnCycType,
          start_date: moment(sftAsgnCycStartDate).format("YYYY-MM-DD"),
          end_date: moment(sftAsgnCycRecurUntil).format("YYYY-MM-DD"),
          no_of_cycle: parseInt(sftAsgnCycNumCount),
          total_period: parseInt(sftAsgnCycRecurCount),
          shift_groups: JSON.stringify(formattedData),
        });

        const allGroupsValid = formattedData.every(
          (item) => item?.group_id?.length > 0
        );

        if (allGroupsValid) {
          try {
            const response = await fetch(
              `${domain}shift-group-assignment/?${params.toString()}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `token ${token}`,
                },
              }
            );
            const res = await response.json();
            if (response.ok) {
              setSftAsgnCycCalenDat(res?.shifts ? res?.shifts : []);
            } else if (response.status === 409) {
              sessionHandling();
            } else {
              throw new Error(res.error);
            }
          } catch (error) {
            setSftAsgnCycCalenDat([]);
          }
        }
      } else {
        setSftAsgnCycCalenDat([]);
      }
    }
  }, [
    sftAsgnCycLab,
    sftAsgnSftGrpData,
    domain,
    token,
    sftAsgnCycNumCount,
    sftAsgnCycRecurCount,
    sftAsgnCycRecurUntil,
    sftAsgnCycStartDate,
    sftAsgnCycType,
    validateCount,
  ]);

  useEffect(() => {
    getCycleCalendarData();
  }, [getCycleCalendarData]);

  useEffect(() => {
    const getSftCatShiftGrpDetails = async () => {
      try {
        const response = await fetch(
          `${domain}shift-group-based-shift-category/?shift_category_id=${sftAsgnCatId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const labels = res.map((obj) => ({
            ...obj,
            label: obj.shift_group_name,
          }));
          setSftAsgnCatTotalShiftGrps([...labels]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };

    if (sftAsgnCatId) {
      getSftCatShiftGrpDetails();
    }
  }, [domain, token, sftAsgnCatId]);

  const CalculateDateBasedOnCount = useCallback(
    (count, type) => {
      const currentDate = sftAsgnCycStartDate;
      let futureDate;

      if (type === "Weekly") {
        futureDate = new Date(
          currentDate.getTime() + count * 7 * 24 * 60 * 60 * 1000
        );
        futureDate.setDate(futureDate.getDate() - 1);
        setSftAsgnCycRecurUntil(futureDate);
      } else if (type === "Monthly") {
        futureDate = new Date(currentDate);
        futureDate.setMonth(futureDate.getMonth() + Math.ceil(count));
        futureDate.setDate(futureDate.getDate() - 1);
        setSftAsgnCycRecurUntil(futureDate);
      }
    },
    [sftAsgnCycStartDate]
  );

  const CalculateCountBasedOnDate = useCallback(
    (endDate, type) => {
      const currentDate = sftAsgnCycStartDate;
      currentDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let difference;

      if (type === "Weekly") {
        const diffInMs = endDate.getTime() - currentDate.getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        difference = Math.ceil(diffInDays / 7);
        setSftAsgnCycRecurCount(difference < 1 ? 1 : difference);
      } else if (type === "Monthly") {
        const yearsDifference =
          endDate.getFullYear() - currentDate.getFullYear();
        const monthsDifference = endDate.getMonth() - currentDate.getMonth();
        difference = yearsDifference * 12 + monthsDifference;
        setSftAsgnCycRecurCount(
          Math.ceil(difference) < 1 ? 1 : Math.ceil(difference)
        );
      }
    },
    [sftAsgnCycStartDate]
  );

  useEffect(() => {
    if (sftAsgnCycType === "Weekly") {
      setSftAsgnCycStartDorM(moment(sftAsgnCycStartDate).format("dddd"));
      setSftAsgnCycRecurCount(1);
      setSftAsgnCycRecurUntil(sftAsgnCycStartDate);
      sftAsgnCycRecurType === "recurtype1"
        ? CalculateDateBasedOnCount(1, "Weekly")
        : CalculateCountBasedOnDate(sftAsgnCycStartDate, "Weekly");
    } else {
      setSftAsgnCycStartDorM(moment(sftAsgnCycStartDate).format("MMMM"));
      setSftAsgnCycRecurCount(1);
      setSftAsgnCycRecurUntil(sftAsgnCycStartDate);
      sftAsgnCycRecurType === "recurtype1"
        ? CalculateDateBasedOnCount(1, "Monthly")
        : CalculateCountBasedOnDate(sftAsgnCycStartDate, "Monthly");
    }
  }, [
    sftAsgnCycType,
    sftAsgnCycStartDate,
    sftAsgnCycRecurType,
    CalculateDateBasedOnCount,
    CalculateCountBasedOnDate,
  ]);

  useEffect(() => {
    if (sftAsgnCatTotalShifts?.length > 0) {
      const shiftGroupRows = sftAsgnCatTotalShifts.map((_, cycleIndex) => {
        const cycleKey = `cycle ${cycleIndex + 1}`;
        const shifts = sftAsgnCatTotalShifts.map((shift) => ({
          shift_id: shift.shift_id,
          shift_name: shift.shift_name,
          start_time: shift.start_time,
          end_time: shift.end_time,
          shift_category_id: shift.shift_category_id,
          groups: [],
        }));

        return {
          [cycleKey]: {
            cycle_id: cycleIndex + 1,
            shifts: shifts,
          },
        };
      });
      setSftAsgnSftGrpData(shiftGroupRows);

      const shiftGroupCombRows = sftAsgnCatTotalShifts.map((_, cycleIndex) => {
        const cycleCombKey = `cycle ${cycleIndex + 1}`;
        return {
          [cycleCombKey]: [],
        };
      });
      setsftAsgnSftGrpCombData(shiftGroupCombRows);
    }
  }, [sftAsgnCatTotalShifts, sftAsgnCatTotalShiftGrps]);

  const transformForPayloadData = (data) => {
    const payloadData = data.reduce((acc, cycle) => {
      const cycleKey = Object.keys(cycle)[0];
      const cycleData = cycle[cycleKey];

      cycleData.shifts.forEach((shift) => {
        const groupIds = shift.groups.map((group) => group.id);

        acc.push({
          cycle_id: cycleData.cycle_id,
          shift_id: shift.shift_id,
          group_id: groupIds,
        });
      });

      return acc;
    }, []);
    return payloadData;
  };

  const handleAutocompleteChange = (cycleIndex, shiftIndex, newValue) => {
    const previousValue =
      sftAsgnSftGrpData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts[
        shiftIndex
      ].groups;

    setSftAsgnSftGrpData((prevData) => {
      const updatedData = [...prevData];
      updatedData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts[
        shiftIndex
      ].groups = newValue;
      return updatedData;
    });

    setsftAsgnSftGrpCombData((prevData) => {
      const updatedCombData = [...prevData];
      const currentCycle =
        updatedCombData[cycleIndex][`cycle ${cycleIndex + 1}`];

      const removedItems = previousValue.filter(
        (prevGroup) =>
          !newValue.some(
            (newGroup) =>
              newGroup.shift_group_name === prevGroup.shift_group_name
          )
      );

      const addedItems = newValue.filter(
        (newGroup) =>
          !previousValue.some(
            (prevGroup) =>
              prevGroup.shift_group_name === newGroup.shift_group_name
          )
      );

      removedItems.forEach((removedGroup) => {
        const index = currentCycle.indexOf(removedGroup.shift_group_name);
        if (index > -1) {
          currentCycle.splice(index, 1);
        }
      });

      addedItems.forEach((addedGroup) => {
        currentCycle.push(addedGroup.shift_group_name);
      });

      return updatedCombData;
    });
  };

  const getFilteredOptions = (cycleIndex, shiftIndex) => {
    const selectedValuesInCycle =
      sftAsgnSftGrpData[cycleIndex][`cycle ${cycleIndex + 1}`].shifts;

    const selectedValues = selectedValuesInCycle
      .filter((_, index) => index !== shiftIndex)
      .flatMap((shift) => shift.groups)
      .map((group) => group.id);

    return sftAsgnCatTotalShiftGrps.filter(
      (option) => !selectedValues.includes(option.id)
    );
  };

  const validateCombData = () => {
    const firstRowValues = sftAsgnSftGrpCombData[0][`cycle 1`];
    const firstRowSet = new Set(firstRowValues);

    for (let i = 1; i < sftAsgnSftGrpCombData.length; i++) {
      const currentRowValues = sftAsgnSftGrpCombData[i][`cycle ${i + 1}`];
      const currentRowSet = new Set(currentRowValues);
      if (
        currentRowSet.size !== firstRowSet.size ||
        !Array.from(firstRowSet).every((value) => currentRowSet.has(value))
      ) {
        setSftAsgnErr(
          `Cycle ${i + 1} does not contain exactly the same groups as Cycle 1.`
        );
        return false;
      }
    }
    setSftAsgnErr("");
    return true;
  };

  const onSubmit = async (data) => {
    const isCombDataValid =
      sftAsgnSftGrpCombData?.length > 1 ? validateCombData() : true;

    const errorMessage = validateCount();
    if (
      isCombDataValid &&
      !errorMessage &&
      sftAsgnErr === "" &&
      !isNaN(parseInt(sftAsgnCycNumCount))
    ) {
      const apiPayloadSftData = transformForPayloadData(sftAsgnSftGrpData);
      try {
        const response = await fetch(`${domain}shift-assignment/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            shift_cycle: sftAsgnCycType,
            start_date: moment(sftAsgnCycStartDate).format("YYYY-MM-DD"),
            end_date: moment(sftAsgnCycRecurUntil).format("YYYY-MM-DD"),
            no_of_cycle: parseInt(sftAsgnCycNumCount),
            shift_category_id: sftAsgnCatId,
            shift_groups: apiPayloadSftData,
            total_period: parseInt(sftAsgnCycRecurCount),
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          updateShowAddShiftAsgn(false);
          setReldSftAsgnApi(true);
          reset();
          setDataValidErr("");
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setDataValidErr(errorMessage);
    }
  };

  return (
    <>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow
              actionFunction={() => {
                updateShowAddShiftAsgn(false);
                dispatch(setGlobalSearchValue(""));
              }}
            />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              {shftAsgnPro} Shift Assignment
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
        <Mui.Grid item md={1} lg={1}></Mui.Grid>
        <Mui.Grid item xs={12} sm={12} md={10} lg={10}>
          <Mui.Card
            sx={{ padding: 5, pt: 2, pb: 2, borderRadius: 4 }}
            elevation={8}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.Typography sx={{ fontWeight: "bold" }}>
                    Category Name
                  </Mui.Typography>
                  <Mui.Autocomplete
                    id="shift-category"
                    size="small"
                    value={sftAsgnCatName}
                    options={allSftCategoryDet}
                    filterOptions={(options, state) => {
                      return options.filter((option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase())
                      );
                    }}
                    renderOption={(props, option) => {
                      return <li {...props}>{option.label}</li>;
                    }}
                    onChange={(event, value) => {
                      if (value) {
                        setSftAsgnSftGrpData([]);
                        setsftAsgnSftGrpCombData([]);
                        setSftAsgnCatName(value.label);
                        setSftAsgnCatId(value.id);
                        getSftCatShiftDetails(value.id);
                        setValue("shiftasgncategory", value.label);
                        setValue("shiftcatDepGrps", []);
                        trigger("shiftasgncategory");
                      }
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <Mui.TextField
                        {...params}
                        {...register("shiftasgncategory", {
                          required: "Shift category is required",
                        })}
                        value={sftAsgnCatName}
                        sx={{ background: themes.whiteColor }}
                        placeholder={"Select shift category"}
                        size="medium"
                        error={errors.shiftasgncategory ? true : false}
                        helperText={
                          errors.shiftasgncategory
                            ? errors.shiftasgncategory.message
                            : ""
                        }
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  mt: 1.5,
                }}
              >
                Shift Cycle
              </Mui.Typography>
              <Mui.Divider />
              <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.Typography sx={{ fontWeight: "bold" }}>
                    Cycle
                  </Mui.Typography>
                  <Controller
                    name="shiftassigncycle"
                    control={control}
                    defaultValue={sftAsgnCycType}
                    rules={{ required: "Select a cycle type" }}
                    render={({ field }) => (
                      <Mui.Select
                        {...field}
                        value={sftAsgnCycType}
                        sx={{ minWidth: "100%" }}
                        onChange={(e) => setSftAsgnCycType(e.target.value)}
                        renderValue={(selected) => selected}
                        error={!!errors.shiftassigncycle}
                      >
                        {cycleTypes.map((cyctype) => (
                          <Mui.MenuItem value={cyctype.type} key={cyctype.id}>
                            <Mui.ListItemText primary={cyctype.type} />
                          </Mui.MenuItem>
                        ))}
                      </Mui.Select>
                    )}
                  />
                  {errors.shiftassigncycle && (
                    <Mui.FormHelperText error>
                      {errors.shiftassigncycle.message}
                    </Mui.FormHelperText>
                  )}
                </Mui.Grid>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.Typography sx={{ fontWeight: "bold" }}>
                    Start From
                  </Mui.Typography>
                  <Mui.Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 1 }}
                  >
                    <Controller
                      name="shiftStartDate"
                      control={control}
                      defaultValue={sftAsgnCycStartDate}
                      rules={{
                        required: "Date is required",
                        validate: (value) => {
                          if (!value || String(value) === "Invalid Date") {
                            return "Date is required";
                          }
                          const dateValue = new Date(value);
                          if (
                            dateValue < new Date() &&
                            dateValue.toDateString() !==
                              new Date().toDateString()
                          ) {
                            return "Date cannot be before the current date";
                          }
                          return true;
                        },
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            {...field}
                            value={sftAsgnCycStartDate}
                            minDate={new Date()}
                            sx={{ minWidth: "49%" }}
                            format="dd/MM/yyyy"
                            onChange={(newValue) => {
                              if (
                                newValue !== null ||
                                String(newValue) === "Invalid Date"
                              ) {
                                setSftAsgnCycRecurUntil(newValue);
                                setSftAsgnCycStartDate(newValue);
                              }
                              field.onChange(newValue);
                            }}
                            renderInput={(params) => (
                              <Mui.TextField
                                sx={{ minWidth: "100%" }}
                                {...params}
                                error={Boolean(error)}
                                helperText={Boolean(error) && error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <Mui.TextField
                      sx={{ minWidth: "49%" }}
                      value={sftAsgnCycStartDorM}
                      disabled
                    />
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.Typography sx={{ fontWeight: "bold" }}>
                    No. of Cycles
                  </Mui.Typography>
                  <Controller
                    name="shiftCycleCount"
                    control={control}
                    value={sftAsgnCycNumCount}
                    rules={{
                      required: "This field is required",
                      min: { value: 1, message: "Minimum value is 1" },
                      pattern: {
                        value: /^\d+$/,
                        message: "Must be a valid number",
                      },
                    }}
                    render={({ field }) => (
                      <Mui.TextField
                        {...field}
                        type="number"
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          min: 1,
                        }}
                        value={sftAsgnCycNumCount}
                        onChange={(e) => {
                          setSftAsgnCycNumCount(e.target.value);
                          field.onChange(e.target.value);
                        }}
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          fontSize: 14,
                          width: "100%",
                        }}
                        error={!!errors.shiftCycleCount}
                        helperText={errors.shiftCycleCount?.message}
                      />
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  mt: 1.5,
                }}
              >
                Repeat Until
              </Mui.Typography>
              <Mui.Divider />
              <Mui.Grid container spacing={1} sx={{ mt: 1 }}>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.FormControlLabel
                    value="recurtype1"
                    control={
                      <Mui.Radio
                        checked={sftAsgnCycRecurType === "recurtype1"}
                        onChange={(e) => setSftAsgnCycRecurType(e.target.value)}
                      />
                    }
                    label="Total Period"
                  />
                  <Mui.Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 1 }}
                  >
                    <Controller
                      name="occurrencesCount"
                      control={control}
                      value={sftAsgnCycRecurCount}
                      disabled={
                        sftAsgnCycRecurType === "recurtype2" ? true : false
                      }
                      rules={{
                        required:
                          sftAsgnCycRecurType === "recurtype1" &&
                          "This field is required",
                        min: sftAsgnCycRecurType === "recurtype1" && {
                          value: 1,
                          message: "Minimum value is 1",
                        },

                        max: sftAsgnCycRecurType === "recurtype1" && {
                          value: sftAsgnCycType === "Weekly" ? 52 : 12,
                          message:
                            sftAsgnCycType === "Weekly"
                              ? "Maximum value is 52"
                              : "Maximum value is 12",
                        },
                        pattern: sftAsgnCycRecurType === "recurtype1" && {
                          value: /^\d+$/,
                          message: "Must be a valid number",
                        },
                      }}
                      render={({ field }) => (
                        <Mui.TextField
                          {...field}
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            min: 1,
                          }}
                          value={sftAsgnCycRecurCount}
                          onChange={(e) => {
                            setSftAsgnCycRecurCount(e.target.value);
                            CalculateDateBasedOnCount(
                              e.target.value,
                              sftAsgnCycType
                            );
                            field.onChange(e.target.value);
                          }}
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            fontSize: 14,
                            width: "100%",
                          }}
                          error={!!errors.occurrencesCount}
                          helperText={errors.occurrencesCount?.message}
                        />
                      )}
                    />
                    <Mui.TextField
                      sx={{ minWidth: "49%" }}
                      value={sftAsgnCycType === "Weekly" ? "Weeks" : "Months"}
                      disabled
                    />
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ paddingBottom: 1 }}
                >
                  <Mui.FormControlLabel
                    value="recurtype2"
                    control={
                      <Mui.Radio
                        checked={sftAsgnCycRecurType === "recurtype2"}
                        onChange={(e) => setSftAsgnCycRecurType(e.target.value)}
                      />
                    }
                    label="Until Date"
                  />
                  <Controller
                    name="untilDate"
                    control={control}
                    defaultValue={sftAsgnCycRecurUntil}
                    rules={{
                      required: "Date is required",
                      validate: (value) => {
                        if (!value || String(value) === "Invalid Date") {
                          return "Date is required";
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          {...field}
                          value={sftAsgnCycRecurUntil}
                          minDate={sftAsgnCycStartDate}
                          maxDate={dayjs(sftAsgnCycStartDate)
                            .add(1, "year")
                            .subtract(1, "day")
                            .toDate()}
                          sx={{ minWidth: "100%" }}
                          format="dd/MM/yyyy"
                          onChange={(newValue) => {
                            if (
                              newValue !== null ||
                              String(newValue) === "Invalid Date"
                            ) {
                              setSftAsgnCycRecurUntil(newValue);
                              CalculateCountBasedOnDate(
                                newValue,
                                sftAsgnCycType
                              );
                            }
                            field.onChange(newValue);
                          }}
                          disabled={
                            sftAsgnCycRecurType === "recurtype1" ? true : false
                          }
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ minWidth: "100%" }}
                              error={Boolean(error)}
                              helperText={Boolean(error) && error?.message}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Mui.Grid>
              </Mui.Grid>
              {dataValidErr !== "" ? (
                <h4 style={{ color: themes.helperTextColor, marginTop: 1 }}>
                  {dataValidErr}
                </h4>
              ) : null}
              <Mui.Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  mt: 1.5,
                }}
              >
                Shift Arrangement
              </Mui.Typography>

              {sftAsgnSftGrpData?.length > 0 ? (
                <>
                  <Mui.TableContainer
                    component={Mui.Paper}
                    sx={{ width: "100%" }}
                  >
                    <Mui.Table>
                      <Mui.TableHead>
                        <Mui.TableRow>
                          <Mui.TableCell></Mui.TableCell>
                          {sftAsgnSftGrpData[0][`cycle 1`].shifts.map(
                            (shift, colIndex) => (
                              <Mui.TableCell key={colIndex}>
                                <Mui.Stack direction="column">
                                  <Mui.Typography
                                    style={{
                                      paddingTop: "0px",
                                      paddingBottom: "0px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {shift.shift_name}
                                  </Mui.Typography>
                                  <Mui.Typography>
                                    {moment(
                                      shift.start_time,
                                      "HH:mm:ss"
                                    ).format("h:mm a")}{" "}
                                    -{" "}
                                    {moment(shift.end_time, "HH:mm:ss").format(
                                      "h:mm a"
                                    )}
                                  </Mui.Typography>
                                </Mui.Stack>
                              </Mui.TableCell>
                            )
                          )}
                        </Mui.TableRow>
                      </Mui.TableHead>
                      <Mui.TableBody>
                        {sftAsgnSftGrpData.map((cycleData, cycleIndex) => {
                          const cycleKey = `cycle ${cycleIndex + 1}`;
                          const cycle = cycleData[cycleKey];

                          return (
                            <Mui.TableRow key={cycleKey}>
                              <Mui.TableCell>
                                <Mui.Typography style={{ fontWeight: "bold" }}>
                                  {cycleKey}
                                </Mui.Typography>
                              </Mui.TableCell>

                              {cycle.shifts.map((shift, shiftIndex) => (
                                <Mui.TableCell key={shiftIndex}>
                                  <Controller
                                    name={`shiftcatDepGrps[${cycleIndex}][${shiftIndex}]`}
                                    control={control}
                                    defaultValue={
                                      sftAsgnSftGrpData[cycleIndex][
                                        `cycle ${cycleIndex + 1}`
                                      ][cycleData]
                                    }
                                    rules={{
                                      required: "Select at least one group",
                                    }}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Mui.Autocomplete
                                        multiple
                                        size="small"
                                        options={getFilteredOptions(
                                          cycleIndex,
                                          shiftIndex
                                        )}
                                        value={
                                          sftAsgnSftGrpData[cycleIndex][
                                            `cycle ${cycleIndex + 1}`
                                          ][cycleData]
                                        }
                                        getOptionLabel={(option) =>
                                          option?.label ? option.label : ""
                                        }
                                        filterSelectedOptions
                                        onChange={(event, value) => {
                                          field.onChange(value);
                                          handleAutocompleteChange(
                                            cycleIndex,
                                            shiftIndex,
                                            value
                                          );
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                          <Mui.TextField
                                            {...params}
                                            sx={{
                                              background: themes.whiteColor,
                                            }}
                                            placeholder="Select groups"
                                            value={
                                              sftAsgnSftGrpData[cycleIndex][
                                                `cycle ${cycleIndex + 1}`
                                              ][cycleData]
                                            }
                                            size="medium"
                                            error={!!error}
                                            helperText={
                                              error ? error.message : ""
                                            }
                                          />
                                        )}
                                      />
                                    )}
                                  />
                                </Mui.TableCell>
                              ))}
                            </Mui.TableRow>
                          );
                        })}
                      </Mui.TableBody>
                    </Mui.Table>
                  </Mui.TableContainer>
                  {sftAsgnErr !== "" ? (
                    <h4 style={{ color: themes.helperTextColor, marginTop: 1 }}>
                      {sftAsgnErr}
                    </h4>
                  ) : null}
                </>
              ) : (
                <Mui.Alert severity="info">No shifts available</Mui.Alert>
              )}

              {sftAsgnSftGrpData?.length > 0 &&
              sftAsgnCycLabData?.length > 0 ? (
                <>
                  <Mui.Grid container spacing={1} sx={{ mt: 3, mb: 2 }}>
                    <Mui.Grid item xs={12} sm={6} md={4} lg={2}>
                      <Mui.Typography sx={{ fontWeight: "bold" }}>
                        Cycle
                      </Mui.Typography>
                      <Mui.Select
                        value={sftAsgnCycLab}
                        fullWidth
                        sx={{ minWidth: "100%" }}
                        onChange={(e) => setSftAsgnCycLab(e.target.value)}
                        renderValue={(selected) => selected}
                      >
                        {sftAsgnCycLabData.map((cyc) => (
                          <Mui.MenuItem value={cyc.label} key={cyc.label}>
                            <Mui.ListItemText primary={cyc.label} />
                          </Mui.MenuItem>
                        ))}
                      </Mui.Select>
                    </Mui.Grid>
                  </Mui.Grid>

                  {sftAsgnCycCalenDat.length > 0 ? (
                    <Mui.Grid container spacing={{ xs: 2, md: 1 }}>
                      {sftAsgnCycCalenDat.map((shift, index) => (
                        <Mui.Grid
                          item
                          xs={4}
                          sm={4}
                          md={2}
                          lg={2}
                          key={index}
                          sx={{ borderColor: themes.primary }}
                        >
                          <Mui.Card>
                            <Mui.Stack
                              direction="column"
                              width={"100%"}
                              sx={{
                                backgroundColor: themes.whiteColor,
                                padding: 2,
                                textAlign: "center",
                              }}
                            >
                              <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                              >
                                {sftAsgnCycType === "Weekly"
                                  ? `Week - ${shift?.week_number}`
                                  : `Month - ${shift?.month_number}`}
                              </Mui.Typography>
                              <Mui.Typography
                                sx={{ fontSize: 12, fontWeight: "bold" }}
                              >
                                {shift?.shift_name}
                              </Mui.Typography>
                            </Mui.Stack>
                          </Mui.Card>
                        </Mui.Grid>
                      ))}
                    </Mui.Grid>
                  ) : (
                    <Mui.Alert severity="info">
                      No shift cycle available
                    </Mui.Alert>
                  )}
                </>
              ) : null}

              <Mui.Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 2 }}
              >
                <Mui.Grid item>
                  <Mui.Button
                    variant="contained"
                    sx={{
                      background: themes.primaryButton,
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: themes.primaryButton,
                      },
                      color: themes.headLine,
                    }}
                    onClick={() => updateShowAddShiftAsgn(false)}
                  >
                    Cancel
                  </Mui.Button>
                </Mui.Grid>
                <Mui.Grid item>
                  <Mui.Button
                    variant="contained"
                    sx={{
                      background: themes.primaryButton,
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: themes.primaryButton,
                      },
                      color: themes.headLine,
                    }}
                    type="submit"
                  >
                    Assign
                  </Mui.Button>
                </Mui.Grid>
              </Mui.Grid>
            </form>
          </Mui.Card>
        </Mui.Grid>
        <Mui.Grid item md={1} lg={1}></Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default AddShiftAssign;
