import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { themes } from "services/constants";
import { Delete, Edit } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
import { AddNewSalaryGroup } from "./AddNewSalaryGroup";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";

import { EditSalaryGroup } from "./EditSalaryGroup";
const keys = ["name", "source_bank_detail_name", "source_bank_detail"];
export const SalaryGroup = ({
  companyId,
  currentScreen,
  companyChange,
  setCompanyChange,
  addNewSalaryGroup,
  setAddNewSalaryGroup,
  editSalary,
  setEditSalary,
  employeeDetails,
  reCallEmployeeApi,
}) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editSalaryGroupDetails, setEditSalaryGroupDetails] = useState({});
  const [companyFilter, setCompanyFilter] = useState(false);
  const [updateEditDetails, setUpdateEditDetails] = useState(false);
  const [bankDetailsData, setBankDetailsData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const {
    result: salaryGroupData,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    companyId !== 0 ? `${domain}salary-group/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyChange === true && currentScreen === "Salary Group") {
      reCallApi(true);
      setCompanyChange(false);
    }
    setAddNewSalaryGroup(false);
    setEditSalary(false);
  }, [
    companyChange,
    currentScreen,
    reCallApi,
    setAddNewSalaryGroup,
    setCompanyChange,
    setEditSalary,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateDeleteSalaryGroup = async () => {
    setButtonDisabled(true);
    try {
      const response = await fetch(
        `${domain}salary-group/${editSalaryGroupDetails.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({ isdeleted: true }),
        }
      );
      const res = await response.json();
      if (response.ok) {
        showToast("error", "Deleted successfully");
        reCallApi(true);
        setOpenDialog(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleCloseDialogBox = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (updateData && salaryGroupData?.length !== 0 && salaryGroupData) {
      setBankDetailsData(salaryGroupData);
      setUpdateData(false);
    }
  }, [salaryGroupData, updateData]);

  useEffect(() => {
    if (salaryGroupData?.length !== 0 || salaryGroupData?.length === 0) {
      const filteredResults = salaryGroupData?.filter((item) =>
        keys.some((key) =>
          item[key]
            ?.toString()
            .toLowerCase()
            .includes(globalSearchValue.toString().toLowerCase())
        )
      );
      setBankDetailsData(filteredResults);
      setPage(0);
    }
  }, [globalSearchValue, salaryGroupData]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={9}>
          {addNewSalaryGroup || editSalary ? (
            <Mui.Stack direction="row">
              <BackArrow
                actionFunction={() => {
                  reCallApi(true);
                  setAddNewSalaryGroup(false);
                  setEditSalary(false);
                  dispatch(setGlobalSearchValue(""));
                }}
              />
              <Mui.Typography
                sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
              >
                {editSalary ? "Edit salary group" : "Add New Salary Group"}
              </Mui.Typography>
            </Mui.Stack>
          ) : null}
        </Mui.Grid>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          {addNewSalaryGroup || editSalary ? null : (
            <CustomButton
              actionFuntion={() => {
                setAddNewSalaryGroup(true);
                reCallEmployeeApi(true);
                dispatch(setGlobalSearchValue(""));
              }}
              actionName="Add salary group"
              typeName="button"
            />
          )}
        </Mui.Grid>
        {loading && !addNewSalaryGroup && !editSalary ? (
          <Loader />
        ) : (
          <Mui.TableContainer
            sx={{
              mt: 2,
              width: "100%",
              paddingLeft: 1,
              paddingRight: 1,
              maxHeight: 850,
            }}
          >
            <Mui.Table>
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Salary group name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Source bank name
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    No of employees
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    Action
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {addNewSalaryGroup ? null : bankDetailsData?.length === 0 &&
                  !editSalary ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={4} align="center">
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : editSalary ? null : (
                  bankDetailsData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((salary, index) => (
                      <React.Fragment>
                        <Mui.TableRow>
                          <Mui.TableCell>{salary?.name}</Mui.TableCell>
                          <Mui.TableCell>
                            {salary?.source_bank_detail_name}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {salary?.users_detail
                              ? salary?.users_detail?.length
                              : 0}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.IconButton
                              onClick={() => {
                                setEditSalaryGroupDetails(salary);
                                setUpdateEditDetails(true);
                                setEditSalary(true);
                                reCallEmployeeApi(true);
                                dispatch(setGlobalSearchValue(""));
                              }}
                            >
                              <Edit />
                            </Mui.IconButton>
                            <Mui.IconButton
                              onClick={() => {
                                setEditSalaryGroupDetails(salary);
                                setOpenDialog(true);
                              }}
                            >
                              <Delete />
                            </Mui.IconButton>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
        )}
        {addNewSalaryGroup || editSalary ? null : (
          <Mui.Grid
            container
            sx={{
              paddingTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            {bankDetailsData.length > 10 && (
              <Mui.TablePagination
                className="custom-pagination"
                component="div"
                rowsPerPageOptions={[10, 20, 50, 100]}
                count={bankDetailsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Mui.Grid>
        )}
      </Mui.Grid>
      {addNewSalaryGroup ? (
        <AddNewSalaryGroup
          companyId={companyId}
          setAddNewSalaryGroup={setAddNewSalaryGroup}
          setCompanyFilter={setCompanyFilter}
          companyFilter={companyFilter}
          reCallApi={reCallApi}
          employeeDetails={employeeDetails}
          reCallEmployeeApi={reCallEmployeeApi}
        />
      ) : null}
      {editSalary ? (
        <EditSalaryGroup
          companyId={companyId}
          setCompanyFilter={setCompanyFilter}
          companyFilter={companyFilter}
          reCallApi={reCallApi}
          editSalaryGroupDetails={editSalaryGroupDetails}
          setEditSalary={setEditSalary}
          setUpdateEditDetails={setUpdateEditDetails}
          updateEditDetails={updateEditDetails}
          employeeDetails={employeeDetails}
          reCallEmployeeApi={reCallEmployeeApi}
        />
      ) : null}
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialogBox}
        open={openDialog}
        title="Delete Salary Group"
      >
        <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
          Are you sure ,you want to delete the{" "}
          <span style={{ color: themes.redColor }}>
            {editSalaryGroupDetails?.name}
          </span>{" "}
          salary group ?
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: 1,
            }}
          >
            <CustomButton
              actionFuntion={() => setOpenDialog(false)}
              actionName="No"
              typeName="button"
            />
            &nbsp;&nbsp;
            <CustomButton
              actionFuntion={() => updateDeleteSalaryGroup()}
              actionName="Yes"
              typeName="button"
              diableAction={
                openDialog && buttonDisabled ? buttonDisabled : false
              }
            />
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
