import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { themes, leaveCreditingShedule } from "services/constants";
import { showToast } from "services/utils/Status";
import { MoreVert, Info } from "@mui/icons-material";
import { Loader } from "components/Loader";
import { leaveTypeHrms } from "services/constants";
import { useForm } from "react-hook-form";
import * as Mui from "@mui/material";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { LeaveTypeForm } from "./LeaveTypeForm";
import sessionHandling from "services/utils/notificationUtils";

export const LeaveType = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [editLeaveType, setEditLeaveType] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [hideData, setHideData] = useState(false);
  const [formPage, setFormPage] = useState(1);
  const [firstFormPageData, setFirstFormPageData] = useState({
    leavename: "",
    labelColor: themes.primaryIndicator,
    leaveCount: "",
    beyondCurrentLeaveBalance: "",
  });
  const [masterLeaveType, setmasterLeaveType] = useState([]);
  const [apiCall, setApiCall] = useState(true);
  const [carriedForwardData, setCarriedForwardData] = useState("");
  const [availabilityPeriod, setAvailabilityPeriod] = useState("");
  const [joinedDate, setJoinedDate] = useState("");
  const [loadData, setLoadData] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [leaveCreditingSheduleData, setLeaveCreditingSheduleData] =
    useState("");
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const carriedForward = [{ label: "Yes" }, { label: "No" }];

  const handleChange = (event, value) => {
    setCarriedForwardData(value?.label);
    setHideData(value?.label === "Yes" ? true : false);
    if (value?.label === "No") {
      setValue("percentage", null);
      setAvailabilityPeriod("");
    }
  };

  useEffect(() => {
    const getLeaveTypeDetails = async () => {
      setLoader(true);
      try {
        const response = await fetch(`${domain}leave-types/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          setLeaveTypes(res);
          setmasterLeaveType(res);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoader(false);
      }
    };
    if (apiCall) {
      getLeaveTypeDetails();
      setApiCall(false);
    }
  }, [apiCall, domain, token]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(false);
    handleClose();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setEditLeaveType("");
    setDeletePopup(false);
    setHideData(false);
    setCarriedForwardData("");
    setAvailabilityPeriod("");
    setJoinedDate("");
    setLeaveCreditingSheduleData("");
    setFormPage(1);
    setHideData(false);
    reset();
  };

  const createNewLeaveType = async (data) => {
    if (popupTitle === "Add LeaveType") {
      setButtonDisabled(true);
      try {
        const response = await fetch(`${domain}leave-types/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            leave_type: firstFormPageData.leavename,
            leave_color: firstFormPageData.labelColor,
            leave_per_period: firstFormPageData.leaveCount,
            can_apply_beyond_balance:
              firstFormPageData.beyondCurrentLeaveBalance,
            policy: {
              is_carry_forward_allowed: data.carryforward,
              carry_forward_percentage: parseInt(data.percentage),
              carry_forward_availability_period: availabilityPeriod,
              proportionate_leaves_on_joined_date: data.joinedDate,
              leave_crediting_schedule: leaveCreditingSheduleData,
            },
          }),
        });
        const res = await response.json();
        if (response.ok) {
          handleClose();
          reset();
          showToast("success", "Leave type created successfully");
          setButtonDisabled(false);
          setApiCall(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setButtonDisabled(false);
      }
    } else {
      const id = getValues("id");
      setButtonDisabled(true);
      try {
        const response = await fetch(`${domain}leave-types/${id}/`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            leave_type: firstFormPageData.leavename,
            leave_color: firstFormPageData.labelColor,
            leave_per_period: firstFormPageData.leaveCount,
            can_apply_beyond_balance:
              firstFormPageData.beyondCurrentLeaveBalance,
            policy: {
              is_carry_forward_allowed: data.carryforward,
              carry_forward_percentage: parseInt(data.percentage),
              carry_forward_availability_period: availabilityPeriod,
              proportionate_leaves_on_joined_date: data.joinedDate,
              leave_crediting_schedule: leaveCreditingSheduleData,
            },
          }),
        });
        const res = await response.json();
        if (response.ok) {
          handleClose();
          reset();
          setEditLeaveType("");
          showToast("success", "Leave type updated successfully");
          setButtonDisabled(false);
          setApiCall(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
        setButtonDisabled(false);
        setButtonDisabled(true);
      }
    }
  };

  const deleteLeaveType = async () => {
    const id = getValues("id");
    setButtonDisabled(true);
    try {
      const response = await fetch(`${domain}leave-types/${id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          isdeleted: true,
          leave_per_period: firstFormPageData.leaveCount,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        handleClose();
        reset();
        setApiCall(true);
        showToast("error", "Leave type deleted successfully");
        setButtonDisabled(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonDisabled(false);
    }
  };

  const handleKeyPressOnlyNumber = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const filteredResults = masterLeaveType.filter((item) =>
      leaveTypeHrms.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLeaveTypes(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterLeaveType]);

  const addButtonAction = () => {
    reset();
    setFirstFormPageData({
      leavename: "",
      labelColor: themes.primaryIndicator,
      leaveCount: "",
      beyondCurrentLeaveBalance: "",
    });
    setLoadData(true);
    setPopupTitle("Add LeaveType");
    setDeletePopup(false);
    setOpenDialog(true);
  };

  return loader === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          width: "100%",
          minHeight: "95%",
          paddingTop: 2,
          borderRadius: 4,
          border: `1px solid ${themes.buttonBorder}`,
          justifyContent: "center",
        }}
      >
        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingBottom: 2,
          }}
        >
          <CustomButton
            actionFuntion={addButtonAction}
            actionName="Add Leave Type"
            typeName="button"
          />
        </Mui.Grid>
        <Mui.Table>
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow maxWidth="xl" align="left">
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Leave Name
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Leave Carried Forward
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Leave Per Year
              </Mui.TableCell>
              <Mui.TableCell
                sx={{ color: themes.blackColor, fontWeight: "bold" }}
              >
                Actions
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {leaveTypes
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => (
                <Mui.TableRow maxWidth="xl" align="left">
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    <Mui.Typography>
                      {item?.leave_type ? item?.leave_type : "N/A"}
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    <Mui.Typography>
                      {item?.policy?.is_carry_forward_allowed
                        ? item?.policy?.is_carry_forward_allowed
                        : "N/A"}
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    <Mui.Typography>
                      {item?.leave_per_period ? item?.leave_per_period : "N/A"}
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    <Mui.IconButton
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setValue("id", item?.id);
                        setValue(
                          "carryforward",
                          item?.policy?.is_carry_forward_allowed
                        );
                        setValue(
                          "percentage",
                          item?.policy?.carry_forward_percentage
                        );
                        setValue(
                          "availabilityPeriod",
                          item?.policy?.carry_forward_availability_period
                        );
                        setValue(
                          "joinedDate",
                          item?.policy?.proportionate_leaves_on_joined_date
                        );
                        setValue(
                          "leaveCredit",
                          item?.policy?.leave_crediting_schedule
                        );
                        setCarriedForwardData(
                          item?.policy?.is_carry_forward_allowed
                        );
                        setAvailabilityPeriod(
                          item?.policy?.carry_forward_availability_period
                        );
                        setJoinedDate(
                          item?.policy?.proportionate_leaves_on_joined_date
                        );
                        setLeaveCreditingSheduleData(
                          item?.policy?.leave_crediting_schedule
                        );
                        setFirstFormPageData({
                          leavename: item.leave_type,
                          labelColor: item.leave_color,
                          leaveCount: item.leave_per_period,
                          beyondCurrentLeaveBalance:
                            item.can_apply_beyond_balance,
                        });
                      }}
                    >
                      <MoreVert />
                    </Mui.IconButton>
                    <Mui.Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                      elevation={1}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      <Mui.MenuItem
                        sx={{ width: "100%" }}
                        onClick={() => {
                          setHideData(
                            getValues("carryforward") === "Yes" ? true : false
                          );
                          setLoadData(true);
                          setPopupTitle("Edit LeaveType");
                          setEditLeaveType(getValues("leaveTypes"));
                          setDeletePopup(false);
                          setOpenDialog(true);
                          setAnchorEl(false);
                        }}
                      >
                        Edit
                      </Mui.MenuItem>
                      <Mui.MenuItem
                        sx={{ width: "100%" }}
                        onClick={() => {
                          setAnchorEl(false);
                          setDeletePopup(true);
                          setOpenDialog(true);
                          setPopupTitle("Delete Leave Type");
                        }}
                      >
                        Delete
                      </Mui.MenuItem>
                    </Mui.Menu>
                  </Mui.TableCell>
                </Mui.TableRow>
              ))}
          </Mui.TableBody>
        </Mui.Table>
        {leaveTypes.length === 0 && loader === false ? (
          <Mui.Alert sx={{ marginTop: 3 }} severity="info">
            No data available
          </Mui.Alert>
        ) : null}

        {leaveTypes.length > 10 ? (
          <Mui.TablePagination
            className="custom-pagination"
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={leaveTypes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Mui.TableContainer>
      <GlobalDialogBox
        handleCloseDialog={handleClose}
        open={openDialog}
        title={popupTitle === "Add LeaveType" ? "Add Leave Type" : popupTitle}
        minWidth={700}
      >
        {deletePopup ? (
          <Mui.Grid container>
            <Mui.Grid xs={12}>
              <Mui.Typography sx={{ paddingBottom: 2, fontSize: 22 }}>
                Are you sure you want to delete{" "}
                <b fontSize={22} style={{ color: themes.redColor }}>
                  {firstFormPageData.leavename}
                </b>
                ?
              </Mui.Typography>
            </Mui.Grid>
            <Mui.Grid xs={12}>
              <Mui.Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    handleClose();
                  }}
                  actionName="No"
                  typeName="submit"
                />
                &nbsp;
                <CustomButton
                  actionFuntion={() => {
                    setOpenDialog(false);
                    deleteLeaveType();
                  }}
                  disableAction={
                    openDialog && buttonDisabled ? buttonDisabled : false
                  }
                  actionName="Yes"
                  typeName="submit"
                />
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        ) : popupTitle === "Add LeaveType" ||
          popupTitle === "Edit LeaveType" ? (
          <>
            <Mui.Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 2,
              }}
            >
              <Mui.Button
                color="primary"
                size="small"
                sx={{
                  borderRadius: 30,
                  minWidth: 25,
                  backgroundColor:
                    formPage === 1 ? themes.primary : themes.iconColor,
                  "&:hover": {
                    backgroundColor:
                      formPage === 1 ? themes.primary : themes.iconColor,
                  },
                }}
              >
                1
              </Mui.Button>
              &nbsp;
              <Mui.Button
                color="primary"
                size="small"
                sx={{
                  borderRadius: 30,
                  minWidth: 25,
                  backgroundColor:
                    formPage === 2 ? themes.primary : themes.iconColor,
                  "&:hover": {
                    backgroundColor:
                      formPage === 2 ? themes.primary : themes.iconColor,
                  },
                }}
              >
                2
              </Mui.Button>
            </Mui.Stack>
            {formPage === 1 ? (
              <LeaveTypeForm
                popupTitle={popupTitle}
                editLeaveType={editLeaveType}
                setFormPage={setFormPage}
                setFirstFormPageData={setFirstFormPageData}
                firstFormPageData={firstFormPageData}
                setLoadData={setLoadData}
                loadData={loadData}
              />
            ) : (
              <form onSubmit={handleSubmit(createNewLeaveType)}>
                <Mui.Grid container sx={{ paddingBottom: 1 }}>
                  <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                    <Mui.Stack direction="Row">
                      <Mui.Typography>
                        Is carried forward allowed
                      </Mui.Typography>
                      &nbsp;
                      <Mui.Tooltip title="If an employee has some leave balance remaining in previous leave period, that leaves will get added to the current leave period. ">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={carriedForwardData}
                      options={carriedForward}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          {...register("carryforward", {
                            required: "Carried forward allowed is required",
                          })}
                          error={Boolean(errors.carryforward)}
                          helperText={errors.carryforward?.message || ""}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                {hideData ? (
                  <>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}>
                      <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                        <Mui.Stack direction="Row">
                          <Mui.Typography>
                            Percentage of Leave Carried Forward
                          </Mui.Typography>{" "}
                          &nbsp;
                          <Mui.Tooltip title="If only a percentage of remaining leave days should be carried forward to next leave period. Should be between 0 to 100. Effective only when leave carry forwarding is enabled.">
                            <Info />
                          </Mui.Tooltip>
                        </Mui.Stack>
                      </Mui.Grid>
                      <Mui.Grid xs={6}>
                        <Mui.TextField
                          placeholder="Enter the carried leave"
                          {...register("percentage", {
                            required:
                              "Percentage of leave carried forward is reqired",
                            validate: {
                              leaveNameAvailable: async (fieldValue) => {
                                if (parseInt(fieldValue) > 100) {
                                  return "Percentage should be with in the 100%";
                                } else {
                                  return true;
                                }
                              },
                            },
                          })}
                          size="small"
                          InputProps={{
                            inputProps: { maxLength: 3 },
                          }}
                          error={Boolean(errors.percentage)}
                          helperText={errors.percentage?.message || ""}
                          onKeyPress={handleKeyPressOnlyNumber}
                          fullWidth
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                    <Mui.Grid container sx={{ paddingBottom: 1 }}>
                      <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                        <Mui.Stack direction="Row">
                          <Mui.Typography>
                            Carried Froward leave availability period
                          </Mui.Typography>{" "}
                          &nbsp;
                          <Mui.Tooltip title="For how many days carried forward leaves are available from start date of next leave period.">
                            <Info />
                          </Mui.Tooltip>
                        </Mui.Stack>
                      </Mui.Grid>
                      <Mui.Grid xs={6}>
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={availabilityPeriod}
                          options={leaveCreditingShedule}
                          sx={{ width: "100%" }}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return <li {...props}>{option.label}</li>;
                          }}
                          ListboxProps={{ style: { maxHeight: "150px" } }}
                          onChange={(event, value) => {
                            setAvailabilityPeriod(value?.label);
                          }}
                          onKeyPress={(e) => {
                            e.preventDefault();
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              {...register("availabilityPeriod", {
                                required:
                                  "Carried Froward leave availability period is required",
                              })}
                              error={Boolean(errors.availabilityPeriod)}
                              helperText={
                                errors.availabilityPeriod?.message || ""
                              }
                              sx={{ background: themes.whiteColor }}
                              placeholder="Select"
                              size="small"
                            />
                          )}
                        />
                      </Mui.Grid>
                    </Mui.Grid>
                  </>
                ) : null}
                <Mui.Grid container sx={{ paddingBottom: 1 }}>
                  <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                    <Mui.Stack direction="Row">
                      <Mui.Typography>
                        Proportionate leave on joined date
                      </Mui.Typography>{" "}
                      &nbsp;
                      <Mui.Tooltip title="Whether the available number of leaves should be calculated based on number of days employee work in a given leave period. (e.g if an employee joined in end of June, he/she will only get half of the number of leave days specified for given leave type">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={joinedDate}
                      options={carriedForward}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      onChange={(event, value) => {
                        setJoinedDate(value?.label);
                      }}
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          {...register("joinedDate", {
                            required:
                              "Proportionate leave on joined date is required",
                          })}
                          error={Boolean(errors.joinedDate)}
                          helperText={errors.joinedDate?.message || ""}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container sx={{ paddingBottom: 1 }}>
                  <Mui.Grid xs={6} sx={{ paddingTop: 1 }}>
                    <Mui.Stack direction="Row">
                      <Mui.Typography>Leave Crediting Schedule</Mui.Typography>{" "}
                      &nbsp;
                      <Mui.Tooltip title="When quarterly is selected,leaves count count is split and credited to the user at the start of each quarter, similarly works for half yearly and annual.">
                        <Info />
                      </Mui.Tooltip>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={leaveCreditingSheduleData}
                      options={leaveCreditingShedule}
                      sx={{ width: "100%" }}
                      filterOptions={(options, state) => {
                        return options.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option.label}</li>;
                      }}
                      ListboxProps={{ style: { maxHeight: "100px" } }}
                      onChange={(event, value) => {
                        setLeaveCreditingSheduleData(value?.label);
                      }}
                      onKeyPress={(e) => {
                        e.preventDefault();
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          {...register("leaveCredit", {
                            required: "Leave creaditing Schedule is required",
                          })}
                          error={Boolean(errors.leaveCredit)}
                          helperText={errors.leaveCredit?.message || ""}
                          sx={{ background: themes.whiteColor }}
                          placeholder="Select"
                          size="small"
                        />
                      )}
                    />
                  </Mui.Grid>
                </Mui.Grid>
                <Mui.Grid container>
                  <Mui.Grid item xs={2}>
                    <CustomButton
                      actionFuntion={() => {
                        setLoadData(true);
                        setFormPage(1);
                      }}
                      actionName="Previous"
                      typeName="button"
                    />
                  </Mui.Grid>
                  <Mui.Grid
                    item
                    xs={10}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <CustomButton
                      actionFuntion={handleClose}
                      actionName="Cancel"
                      typeName="button"
                    />
                    &nbsp;
                    <CustomButton
                      actionName={
                        popupTitle === "Add LeaveType" ? "Add" : "Update"
                      }
                      typeName="submit"
                      disableAction={
                        openDialog && buttonDisabled ? buttonDisabled : false
                      }
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </form>
            )}
          </>
        ) : null}
      </GlobalDialogBox>
    </React.Fragment>
  );
};
