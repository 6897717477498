import React, { useState } from "react";
import { Leaves } from "./Leaves";
import { Permissions } from "./Permission";
import { Loans } from "./Loan";
// import { OverTime } from "./OverTIme";
import { MyZoneOverTime } from "./MyZoneOverTime";
import { SettingsMenu } from "./SettingsMenu";
import { AdvanceSalary } from "./AdvanceSalary";
import { WeekOff } from "./WeekOff";
import { Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";

export const MyZoneApply = () => {
  const { approveSettingsScreens } = useSelector((state) => state.hrmsReducer);
  const [currentScreen, setCurrentScreen] = useState(approveSettingsScreens);

  const renderComponent = () => {
    switch (currentScreen) {
      case "Leaves":
        return <Leaves />;
      case "Permissions":
        return <Permissions />;
      case "Over time":
        return <MyZoneOverTime />;
      case "Loans":
        return <Loans />;
      case "Advance Salary":
        return <AdvanceSalary />;
      case "Week Off":
        return <WeekOff />;
      // return <Expense />;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={1.5}>
          <SettingsMenu
            setCurrentScreen={setCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>
        <Grid xs={10.5} sx={{ padding: 2 }}>
          <Paper
            sx={{
              boxShadow: 3,
              minHeight: 890,
            }}
          >
            {renderComponent()}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
