import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import * as Mui from "@mui/material";

const keys = ["leave_type", "from_date", "to_date", "business_days", "status"];

export const Approved = ({ startDate, endDate }) => {
  const { token, domain, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );

  const [expandedRow, setExpandedRow] = useState(null);
  const [leavePage, setLeavePage] = useState(0);
  const [leaverowsPerPage, setLeaveRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const [pastLeaveDataSearchResults, setPastLeaveDataSearchResults] = useState(
    []
  );
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);

  const handleChangeLeavePage = (event, newPage) => {
    setLeavePage(newPage);
  };

  const handleChangeLeaveRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  const handleRowClick = (rowId) => {
    if (expandedRow === rowId) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowId);
    }
  };

  useEffect(() => {
    const getUserDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${domain}leavesummary/?user_id=${userDetails?.id}&start_date=${startDate}&end_date=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPastLeaveDataSearchResults(res?.leave_count?.leave_requests);
          setMasterDataForSearch(res?.leave_count?.leave_requests);
          setLoading(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setLoading(false);
      }
    };

    getUserDetails();
  }, [domain, endDate, startDate, token, userDetails?.id]);

  useEffect(() => {
    const filteredResults = masterDataForSearch?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setPastLeaveDataSearchResults(filteredResults);
    setLeavePage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1 }}>
        <Mui.TableContainer
          sx={{
            height: "100%",
            overflowY: "auto",
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            minWidth: "90%",
            maxWidth: "100%",
          }}
          className="w-96 border  rounded "
        >
          <Mui.Table className="border" sx={{ borderRadius: "20px" }}>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                <Mui.TableCell className="column-cell"></Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>
                    Leave Type
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>
                    {" "}
                    Start Date
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}> End Date</Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>
                    {" "}
                    Business days{" "}
                  </Mui.Typography>
                </Mui.TableCell>
                <Mui.TableCell className="column-cell">
                  <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {" "}
              {pastLeaveDataSearchResults
                ?.slice(
                  leavePage * leaverowsPerPage,
                  leavePage * leaverowsPerPage + leaverowsPerPage
                )
                .map((leave) => (
                  <>
                    <Mui.TableRow key={leave.id}>
                      <Mui.TableCell className="column-cell">
                        {expandedRow === leave.id ? (
                          <Mui.IconButton
                            onClick={() => {
                              setExpandedRow(null);
                            }}
                          >
                            <KeyboardArrowDownIcon
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        ) : (
                          <Mui.IconButton
                            onClick={() => handleRowClick(leave.id)}
                          >
                            <KeyboardArrowRightIcon
                              style={{
                                color: themes.blackColor,
                                fontWeight: "bold",
                              }}
                            />
                          </Mui.IconButton>
                        )}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {leave.leave_type}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {leave.from_date}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {leave.to_date}
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        <Mui.Typography variant="body1" gutterBottom>
                          {leave.business_days}
                        </Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell className="column-cell">
                        {leave.status}
                      </Mui.TableCell>
                    </Mui.TableRow>
                    {expandedRow === leave.id && (
                      <Mui.TableRow>
                        <Mui.TableCell colSpan={12}>
                          <Mui.TableContainer
                            sx={{
                              marginTop: 0,
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "100%px",
                            }}
                            className="w-96 border rounded"
                          >
                            <Mui.Table className="border">
                              <Mui.Typography
                                variant="body1"
                                gutterBottom
                                sx={{ marginLeft: 1, marginTop: 1 }}
                              >
                                <strong>Description :</strong>{" "}
                                <Mui.Typography
                                  fontSize={15}
                                  color="textSecondary"
                                  sx={{ marginLeft: 13 }}
                                >
                                  {leave.description}
                                </Mui.Typography>
                              </Mui.Typography>
                            </Mui.Table>
                          </Mui.TableContainer>
                          {leave.status === "Rejected" ? (
                            <Mui.TableContainer
                              sx={{
                                marginTop: 0.5,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%px",
                              }}
                              className="w-96 border rounded"
                            >
                              <Mui.Table className="border">
                                <Mui.Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ marginLeft: 1, marginTop: 1 }}
                                >
                                  <strong>Reason for Rejection :</strong>{" "}
                                  <Mui.Typography
                                    fontSize={15}
                                    color="textSecondary"
                                    sx={{ marginLeft: 13 }}
                                  >
                                    {leave.reason}
                                  </Mui.Typography>
                                </Mui.Typography>
                              </Mui.Table>
                            </Mui.TableContainer>
                          ) : null}
                        </Mui.TableCell>
                      </Mui.TableRow>
                    )}
                  </>
                ))}
            </Mui.TableBody>
          </Mui.Table>
          {pastLeaveDataSearchResults?.length > 0 ? null : (
            <div style={{ paddingTop: 8 }}>
              <Mui.Alert severity="info">There is no data available</Mui.Alert>
            </div>
          )}
          {pastLeaveDataSearchResults?.length > 10 ? (
            <Mui.TablePagination
              sx={{ marginRight: 7 }}
              className="custom-pagination"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={pastLeaveDataSearchResults?.length}
              rowsPerPage={leaverowsPerPage}
              page={leavePage}
              onPageChange={handleChangeLeavePage}
              onRowsPerPageChange={handleChangeLeaveRowsPerPage}
            />
          ) : null}
        </Mui.TableContainer>
      </Mui.Grid>
    </React.Fragment>
  );
};
