import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { DownloadForOffline } from "@mui/icons-material";
import { BackArrow } from "components/CustomComponents/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { EPFReportTable } from "./EPFReportTable";
import { Loader } from "components/Loader";

const pfFilterKey = [
  "employee_id",
  "user_name",
  "pf_no",
  "employee_pf",
  "employer_pf",
  "total_contribution",
];

export const EPFReport = ({ setShowReport }) => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { result: companyList } = useFetch(`${domain}get-active-company/`);
  const [companyName, setCompanyName] = useState("");
  const [companyId, setCompanyId] = useState(0);
  const [PFDetails, setPFDetails] = useState([]);
  const [pfReportState, setPfReportState] = useState([]);
  const [updateCompanyDetails, setUpdateCompanyDetails] = useState(true);
  const [paySheduleDetails, setPayScheduleDetails] = useState(true);
  const [epfReportDetailsApi, setepfReportDetailsApi] = useState(false);
  const [getPayScheduleDetails, setGetPayScheduleDetails] = useState([]);
  const [payScheduleName, setPayScheduleName] = useState("");
  const [listViewPage, setListViewPage] = useState(0);
  const [loadingPayScheduleDetails, setLoadingPayScheduleDetails] =
    useState(false);
  const {
    result: payShedule,
    loading,
    setLoad: reCallApi,
  } = useFetch(
    companyId !== 0 ? `${domain}payschedule-report/?company=${companyId}` : null
  );

  useEffect(() => {
    if (companyList.length !== 0 && updateCompanyDetails) {
      const data = companyList.filter((company) => {
        return company.isprimary === true;
      });
      setCompanyId(data[0].id);
      setCompanyName(data[0].companyname);
      setUpdateCompanyDetails(false);
    }
  }, [companyList, updateCompanyDetails]);

  useEffect(() => {
    if (payShedule.length !== 0 && paySheduleDetails && !loading) {
      setGetPayScheduleDetails(payShedule[0] ? payShedule[0] : []);
      setPayScheduleName(payShedule[0] ? payShedule[0]?.name : "");
      setPayScheduleDetails(false);
      if (payShedule[0]) {
        setepfReportDetailsApi(true);
        setLoadingPayScheduleDetails(true);
      }
    } else if (payShedule.length === 0) {
      setGetPayScheduleDetails([]);
      setPayScheduleName("");
      setPFDetails([]);
    }
  }, [loading, payShedule, paySheduleDetails]);

  const handleCompanyName = (event, data) => {
    if (data) {
      setCompanyId(data?.id);
      setCompanyName(data?.companyname);
      reCallApi(true);
      setTimeout(function () {
        setPayScheduleDetails(true);
      }, 300);
    }
  };

  useEffect(() => {
    const getEPFReport = async () => {
      try {
        const response = await fetch(
          `${domain}pay-schedule/detailed/pf-report/?pay_schedule_id=${getPayScheduleDetails?.id}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setPFDetails(res?.employee_details);
          setPfReportState(res?.employee_details);
          setLoadingPayScheduleDetails(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        setPFDetails([]);
        setLoadingPayScheduleDetails(false);
        showToast("error", error.message);
      }
    };
    if (epfReportDetailsApi) {
      getEPFReport();
      setepfReportDetailsApi(false);
    }
  }, [domain, epfReportDetailsApi, getPayScheduleDetails?.id, token]);

  const handlePaySchedule = (event, data) => {
    if (data) {
      setGetPayScheduleDetails(data);
      setPayScheduleName(data.name);
      setepfReportDetailsApi(true);
      setLoadingPayScheduleDetails(true);
    }
  };

  const exportEPFReportDetails = () => {
    if (PFDetails.length !== 0) {
      let dataPreparation = [];
      PFDetails?.forEach((element) => {
        let overAllData = {
          user_name: element.user_name,
          employee_id: element.employee_id,
          pf_no: element.pf_no,
          employee_contribution: element.employee_pf,
          company_contribution: element.employer_pf,
          total_contribution: element.total_contribution,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const company_Name = ["Company Name"];
      const pay_ScheduleName = ["PaySchedule Name"];
      const paySchedulePeriod = ["PaySchedule Period"];
      const TableHead = ["Employee Name"];
      TableHead.push("Employee Id");
      TableHead.push("PF No");
      TableHead.push("Employee Contribution");
      TableHead.push("Company Contribution");
      TableHead.push("Total Contribution");
      company_Name.push(companyName);
      pay_ScheduleName.push(payScheduleName);
      paySchedulePeriod.push(
        getPayScheduleDetails.start_date +
          "  to  " +
          getPayScheduleDetails.end_date
      );
      const items = dataPreparation;
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter(
        (field) => field !== "break_details"
      );
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(paySchedulePeriod.join(","));
      csv.unshift(pay_ScheduleName.join(","));
      csv.unshift(company_Name.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = `${payScheduleName}.csv`;
      document.body.appendChild(element);
      element.click();
    }else {
      showToast("error", "No data available");
    }
  };

  useEffect(() => {
    const filteredResults = pfReportState?.filter((item) =>
      pfFilterKey?.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue?.toString().toLowerCase())
      )
    );
    setPFDetails(filteredResults);
    setListViewPage(0);
  }, [globalSearchValue, pfReportState]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingBottom: 1 }}>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 2 }}>
          <BackArrow actionFunction={() => setShowReport(false)} />
        </Mui.Grid>
        <Mui.Grid xs={2.5}>
          <Mui.Typography>Company Name</Mui.Typography>
          <CustomAutoComplete
            dataList={companyList.map((obj) => ({
              ...obj,
              label: obj.companyname,
            }))}
            selectedValue={companyName}
            updateDataFunction={handleCompanyName}
            placeHolder="Please select the company name"
          />
        </Mui.Grid>
        <Mui.Grid xs={5.9}></Mui.Grid>
        <Mui.Grid xs={2.5}>
          <Mui.Typography>Pay Schedule</Mui.Typography>
          <CustomAutoComplete
            dataList={payShedule.map((obj) => ({
              ...obj,
              label: obj.name,
            }))}
            selectedValue={payScheduleName}
            updateDataFunction={handlePaySchedule}
            placeHolder="Please select the Payshedule"
          />
        </Mui.Grid>
        <Mui.Grid xs={0.5} sx={{ paddingTop: 1 }}>
          <Mui.IconButton onClick={() => exportEPFReportDetails()}>
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 50 }}
            />
          </Mui.IconButton>
        </Mui.Grid>
      </Mui.Grid>
      {loadingPayScheduleDetails ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {PFDetails?.length === 0 || payScheduleName === "" ? null : (
            <Mui.Grid container xs={12}>
              <Mui.Grid xs={8.9}></Mui.Grid>
              <Mui.Grid xs={3.1} sx={{ paddingLeft: 0.5 }}>
                <Mui.Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  EPF Report
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  Period {getPayScheduleDetails.start_date} to{" "}
                  {getPayScheduleDetails.end_date}
                </Mui.Typography>
              </Mui.Grid>
            </Mui.Grid>
          )}
          {PFDetails?.length === 0 || payScheduleName === "" ? (
            <Mui.Alert severity="info">There is no available data </Mui.Alert>
          ) : (
            <EPFReportTable
              PFDetails={PFDetails}
              listViewPage={listViewPage}
              setListViewPage={setListViewPage}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};
