import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import Papa from "papaparse";
import {
  DownloadForOffline,
  CloudDownload,
  Description,
} from "@mui/icons-material";
import { Loader } from "components/Loader";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useDispatch } from "react-redux";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BulkImportData } from "./BulkImportData";
import { useSelector } from "react-redux";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
const BulkImportKeys = [
  "rowid",
  "employee_id",
  "user_name",
  "phone_number",
  "department_name",
  "date_joined",
  "designation_name",
  "employment_type_name",
  "leave_group_name",
  "company_name",
];
export const BulkImport = () => {
  const { domain, token, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [importedData, setImportData] = useState([]);
  const [header, setHeader] = useState([]);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [showListView, setShowListView] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [bulkData, setBulkData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const DownloadData = () => {
    const TableHead = ["Employee Id"];
    TableHead.push("Employee Name");
    TableHead.push("Phone No");
    TableHead.push("Department");
    TableHead.push("Date of Joining");
    TableHead.push("Designation");
    TableHead.push("Employment Type");
    TableHead.push("Leave Group");
    TableHead.push("Company");
    let items = [
      {
        employee_id: "ORGAN1234567",
        user_name: "Organflow",
        phone_number: "8300833133",
        department_name: "Front end",
        date_joined: "23-05-2024",
        designation_name: "Developer",
        employment_type_name: "Full time employee",
        leave_group_name: "Sick",
        company_name: "Strco India Private Limited",
      },
    ];
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter((field) => field);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "Sample.csv";
    document.body.appendChild(element);
    element.click();
  };

  const prepareJson = (data, header) => {
    let id = 0,
      json = [];
    if (data.length !== 0) {
      data.forEach((element) => {
        if (element[0]) {
          let array = {
            rowid: id + 1,
            employee_id: element[0],
            user_name: element[1],
            phone_number: Number(element[2]),
            department_name: element[3],
            date_joined: element[4],
            designation_name: element[5],
            employment_type_name: element[6],
            leave_group_name: element[7],
            company_name: element[8],
          };
          id = id + 1;
          json = [...json, array];
        }
      });
      if (json.length !== 0) {
        setLoading(true);
        verfyImportedDataDetails(json);
      } else {
        showToast("error", "No data available in this files");
      }
    }
  };

  const verfyImportedDataDetails = async (json) => {
    try {
      const response = await fetch(`${domain}bulk-employees/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify(json),
      });
      const res = await response.json();
      if (response.ok) {
        setImportData(res);
        setBulkData(res);
        let resData = res.filter((resDetails) => {
          return resDetails.error_messages !== "";
        });
        setButtonDisabled(resData?.length > 0 ? true : false);
        setLoading(false);
        setShowListView(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast(
        "error",
        "Ensure that all fields in the file have values and that the date is in the dd-mm-yyyy format."
      );
      setLoading(false);
    }
  };

  const clearImportedData = () => {
    setImportData([]);
    setShowListView(false);
  };

  useEffect(() => {
    const filteredResults = bulkData.filter((item) =>
      BulkImportKeys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setImportData(filteredResults);
    setPage(0);
  }, [globalSearchValue, bulkData]);

  return (
    <React.Fragment>
      {!loading && !showListView ? (
        <Mui.Grid
          container
          sx={{
            paddingTop: 3,
          }}
        >
          <Mui.Grid xs={2.5}>
            <BackArrow
              actionFunction={() => {
                dispatch(setGlobalSearchValue(""));
                history.push("/admin/teamzone/employee");
              }}
            />
          </Mui.Grid>
          <Mui.Grid
            xs={7}
            sx={{
              paddingTop: "8%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Mui.Box component={Mui.Paper} elevation={3}>
              <Mui.Typography
                sx={{ fontWeight: "bold", fontSize: 22, padding: 2 }}
              >
                Bulk User Import
              </Mui.Typography>
              <Mui.Grid
                container
                sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 2 }}
              >
                <Mui.Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 5,
                    border: `1px solid ${themes.blueColor}`,
                    borderStyle: "dashed",
                  }}
                >
                  <Mui.Stack>
                    <Mui.Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CloudDownload
                        sx={{ color: themes.blueColor }}
                        fontSize="large"
                      />
                    </Mui.Grid>
                    <Mui.Typography sx={{ textAlign: "center", fontSize: 20 }}>
                      Browse your file(s){" "}
                      <Mui.Button component="label">
                        <Mui.Typography
                          sx={{
                            fontSize: 17,
                            textTransform: "capitalize",
                          }}
                        >
                          Import
                        </Mui.Typography>
                        <Mui.TextField
                          style={{ display: "none" }}
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            const files = e.target.files;
                            if (files[0].type === "text/csv") {
                              setFileName(e.target.files[0].name);
                              if (files) {
                                setFileSize(
                                  parseFloat(files[0].size / 1024).toFixed(2) +
                                    " KB"
                                );
                                Papa.parse(files[0], {
                                  complete: function (results) {
                                    let header = results.data[0];
                                    setHeader(header);
                                    prepareJson(results.data.slice(1), header);
                                  },
                                });
                              }
                            } else {
                              showToast("error", "Invalid file format");
                            }
                          }}
                        />
                      </Mui.Button>{" "}
                    </Mui.Typography>
                    <Mui.Typography
                      sx={{ fontSize: 20, color: themes.colorShadow }}
                    >
                      Max 10 MB files are allowed
                    </Mui.Typography>
                  </Mui.Stack>
                </Mui.Grid>
                <Mui.Grid
                  container
                  sx={{
                    paddingTop: 3,
                  }}
                >
                  <Mui.Typography
                    sx={{ fontSize: 15, color: themes.colorShadow }}
                  >
                    Only support .csv file
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider sx={{ paddingLeft: 3, paddingRight: 3 }}>
                Sample File
              </Mui.Divider>
              <Mui.Grid
                container
                sx={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 2 }}
              >
                <Mui.Grid
                  container
                  sx={{
                    borderRadius: 2,
                    border: `0.5px solid ${themes.shadowText}`,
                    borderColor: themes.shadowText,
                  }}
                >
                  <Mui.Grid xs={1.5} sx={{ paddingTop: 1.5, paddingLeft: 1 }}>
                    <Description
                      fontSize="large"
                      sx={{ color: themes.primaryIndicator, fontSize: 45 }}
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={3.5} sx={{ paddingTop: 1 }}>
                    <Mui.Stack>
                      <Mui.Typography>Sample.csv</Mui.Typography>
                      <Mui.Typography>288 B</Mui.Typography>
                    </Mui.Stack>
                  </Mui.Grid>
                  <Mui.Grid
                    xs={7}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <Mui.IconButton onClick={() => DownloadData()}>
                      <DownloadForOffline
                        size="large"
                        sx={{ color: "#25B7D3", fontSize: 50 }}
                      />
                    </Mui.IconButton>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Box>
          </Mui.Grid>
          <Mui.Grid xs={2.5}></Mui.Grid>
        </Mui.Grid>
      ) : loading ? (
        <Loader info="Loading..." />
      ) : null}
      {showListView ? (
        <Mui.Grid container sx={{ paddingTop: 3 }}>
          <BulkImportData
            header={header}
            setBulkData={setBulkData}
            importedData={importedData}
            setImportData={setImportData}
            fileName={fileName}
            setPage={setPage}
            page={page}
            clearImportedData={clearImportedData}
            fileSize={fileSize}
            buttonDisabled={buttonDisabled}
            setButtonDisabled={setButtonDisabled}
            setShowListView={setShowListView}
          />
        </Mui.Grid>
      ) : null}
    </React.Fragment>
  );
};
