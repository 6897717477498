import React, { useState, useEffect } from "react";
import { useFetch } from "services/hooks/useFetch";
import * as Mui from "@mui/material";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { parse, format } from "date-fns";
import { Apply } from "./Apply";
import { Approve } from "./Approve";
import { History } from "./History";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { themes, defaultAllSelection } from "services/constants";
import { useSelector, useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
export const AdvanceSalary = ({ setReloadData }) => {
  const [userName, setUserName] = useState("All");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [departmentChange, setDepartmentChange] = useState(true);
  const [userChange, setUserChange] = useState(true);
  const [leaveChange, setLeaveChange] = useState(true);
  const [userId, setUserId] = useState(defaultAllSelection);
  const [departmentData, setDepartmentData] = useState([]);
  const [overTimeScreen, setOverTimeScreen] = useState("Approve");
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };
  const dispatch = useDispatch();
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );
  const handleChange = (event, newAlignment) => {
    setOverTimeScreen(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };
  const { result: membersData, setLoad: reCallMemberApi } = useFetch(
    `${domain}get-department-based-user/?department_id=${departmentId}&user_id=${userDetails.id}`
  );

  const renderComponent = () => {
    switch (overTimeScreen) {
      case "Approve":
        return (
          <Approve
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            overTimeScreen={overTimeScreen}
            setReloadData={setReloadData}
          />
        );
      case "History":
        return (
          <History
            overTimeScreen={overTimeScreen}
            departmentId={departmentId}
            departmentChange={departmentChange}
            setDepartmentChange={setDepartmentChange}
            setLeaveChange={setLeaveChange}
            leaveChange={leaveChange}
            userChange={userChange}
            setUserChange={setUserChange}
            userId={userId}
            startDate={startDate}
            endDate={endDate}
          />
        );
      case "Apply":
        return <Apply />;
      default:
        break;
    }
  };
  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department_name: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, token]);

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      setUserName("All");
      setUserId(defaultAllSelection);
      setDepartmentChange(true);
      setReloadData(true);
    }
  };

  const updateLeavePeriod = (event, data) => {
    if (data) {
      setFilteredLabel(data?.label);
      setFilteredValue(data?.filteredValue);
      setStartDate(formatDate(data?.start_date));
      setEndDate(formatDate(data?.end_date));
      setLeaveChange(true);
      reCallMemberApi(true);
    }
  };
  const updateUserName = (event, data) => {
    if (data) {
      setUserName(data.label);
      setUserId(data.user_id);
      setUserChange(true);
      reCallMemberApi(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Grid item xs={5}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={overTimeScreen}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Approve"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Approve"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Approve"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                Approve
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                History
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="Apply"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Apply"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "Apply"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Apply"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Apply"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                  "&:focus": {
                    backgroundColor:
                      overTimeScreen === "Apply"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Apply"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                Apply
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
        {overTimeScreen === "Approve" ? (
          <>
            <Mui.Grid xs={4.6}></Mui.Grid>
            <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={departmentData?.map((obj) => ({
                  ...obj,
                  label: obj.department_name,
                }))}
                selectedValue={departmentName}
                updateDataFunction={updateDepartmentName}
                placeHolder="Select department name"
                label="Select Department Name"
              />
            </Mui.Grid>
          </>
        ) : overTimeScreen === "History" ? (
          <>
            <Mui.Grid item xs={2.3} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={departmentData?.map((obj) => ({
                  ...obj,
                  label: obj.department_name,
                }))}
                selectedValue={departmentName}
                updateDataFunction={updateDepartmentName}
                placeHolder="Select department name"
                label="Select Department Name"
              />
            </Mui.Grid>
            <Mui.Grid item xs={2.3} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={membersData?.map((obj) => ({
                  ...obj,
                  label: obj?.user_name,
                }))}
                selectedValue={userName}
                updateDataFunction={updateUserName}
                placeHolder="Select employee name"
                label="Select User Name"
              />
            </Mui.Grid>
            <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
              <CustomAutoComplete
                dataList={leaveDate}
                selectedValue={filteredValue}
                updateDataFunction={updateLeavePeriod}
                placeHolder="Select leave period"
                label={filteredLabel ? filteredLabel : "Leave Period"}
              />
            </Mui.Grid>
          </>
        ) : null}
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
