import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { parse, format } from "date-fns";
import * as Mui from "@mui/material";
import { showToast } from "services/utils/Status";
import sessionHandling from "services/utils/notificationUtils";
import { Apply } from "./Apply";
import { History } from "./History";
import { themes } from "services/constants";
export const AdvanceSalary = () => {
  const [overTimeScreen, setOverTimeScreen] = useState("Apply");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveChange, setLeaveChange] = useState(true);
  const { token, domain } = useSelector((state) => state.tokenReducer);
  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };
  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setOverTimeScreen(newAlignment);
  };

  const renderComponent = () => {
    switch (overTimeScreen) {
      case "Apply":
        return <Apply />;
      case "History":
        return (
          <History
            startDate={startDate}
            endDate={endDate}
            leaveChange={leaveChange}
            setLeaveChange={setLeaveChange}
          />
        );
      default:
        break;
    }
  };

  const updateLeavePeriod = (event, data) => {
    if (data && data.filteredValue) {
      setFilteredLabel(data.label);
      setFilteredValue(data.filteredValue);
      setStartDate(formatDate(data.start_date));
      setEndDate(formatDate(data.end_date));
      dispatch(setGlobalSearchValue(""));
      setLeaveChange(true);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setStartDate(sDate);
          setEndDate(eDate);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, token]);

  return (
    <React.Fragment>
      <Mui.Grid
        container
        sx={{
          paddingTop: 5,
          paddingLeft: 3,
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Mui.Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: 300,
            height: 55,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Mui.ToggleButtonGroup
            value={overTimeScreen}
            exclusive
            onChange={handleChange}
            onClick={() => {
              dispatch(setGlobalSearchValue(""));
            }}
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              position: "absolute",
              borderRadius: "30px",
              backgroundColor: themes.lightBrown,
            }}
          >
            <Mui.ToggleButton
              value="Apply"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                borderRadius: "30px",
                fontWeight: 600,
                border: "none",
                color:
                  overTimeScreen === "Apply"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  overTimeScreen === "Apply"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    overTimeScreen === "Apply"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "Apply"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
                "&:focus": {
                  backgroundColor:
                    overTimeScreen === "Apply"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "Apply"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
            >
              Apply
            </Mui.ToggleButton>
            <Mui.ToggleButton
              onClick={() => {
                dispatch(setGlobalSearchValue(""));
              }}
              value="History"
              sx={{
                flex: 1,
                textTransform: "capitalize",
                fontSize: 18,
                fontWeight: 600,
                borderRadius: "30px",
                border: "none",
                color:
                  overTimeScreen === "History"
                    ? themes.whiteColor
                    : themes.blackColor,
                backgroundColor:
                  overTimeScreen === "History"
                    ? themes.blackColor
                    : themes.lightBrown,
                "&.Mui-selected": {
                  backgroundColor: themes.blackColor,
                  color: themes.whiteColor,
                  "&:hover": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                  },
                  borderRadius: "30px",
                  "&.Mui-selected": {
                    borderRadius: "30px",
                  },
                },
                "&:hover": {
                  backgroundColor:
                    overTimeScreen === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  color:
                    overTimeScreen === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                },
              }}
            >
              History
            </Mui.ToggleButton>
          </Mui.ToggleButtonGroup>
        </Mui.Box>
        <Mui.Grid
          item
          xs={2.6}
          sx={{
            padding: 2,
          }}
        >
          {overTimeScreen === "History" ? (
            <CustomAutoComplete
              dataList={leaveDate}
              selectedValue={filteredValue}
              updateDataFunction={updateLeavePeriod}
              placeHolder="Select leave period"
              label={filteredLabel ? filteredLabel : "Leave Period"}
            />
          ) : null}
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
