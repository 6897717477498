import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { Delete, Edit, Description, Cancel, Info } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import { EditUserDetails } from "./EditUserDetails";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Loader } from "components/Loader";

let multiApiCallRestriction = true;
export const BulkImportData = ({
  header,
  importedData,
  setImportData,
  page,
  setPage,
  fileName,
  clearImportedData,
  fileSize,
  setBulkData,
  buttonDisabled,
  setButtonDisabled,
  setShowListView,
}) => {
  const history = useHistory();
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [removeDialogBox, setRemoveDialogBox] = useState(false);
  const [deleteUser, setDeleteUser] = useState([]);
  const [openSidePeek, setOpenSidePeek] = useState(false);
  const [editEmployeeDetails, setEditEmployeeDetails] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setDeleteUserDetails = async () => {
    const remainingData = importedData.filter((details) => {
      return details.employee_id !== deleteUser.employee_id;
    });
    setImportData(remainingData);
    let resData = remainingData.filter((resDetails) => {
      return resDetails.error_messages !== "";
    });
    setButtonDisabled(resData?.length > 0 ? true : false);
    setRemoveDialogBox(false);
  };

  const CreateUsers = async () => {
    if (multiApiCallRestriction) {
      setLoading(true);
      multiApiCallRestriction = false;
      Promise.all(
        importedData.map((data) =>
          fetch(`${domain}employee-onboard`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({ ...data }),
          }).then((r) => r.json())
        )
      )
        .then(function (responses) {
          responses.forEach((response) => {
            if (response?.message) {
              return response?.message;
            } else {
              showToast("error", response.error);
              return response.error;
            }
          });
          showToast("success", "Users Created Successfully");
          history.push("/admin/teamzone/employee");
          setShowListView(false);
          multiApiCallRestriction = true;
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Mui.Grid container>
      <Mui.Grid container sx={{ paddingBottom: 2 }}>
        <Mui.Grid xs={2} sm={2} md={2.5} lg={3} xl={3}>
          <Mui.Grid
            container
            sx={{ border: `1px solid ${themes.shadowText}`, borderRadius: 4 }}
          >
            <Mui.Grid
              xs={2}
              sm={2}
              md={2.5}
              lg={2.5}
              xl={3}
              sx={{ paddingTop: 1.5, paddingLeft: 1 }}
            >
              <Description
                fontSize="large"
                sx={{ color: themes.primaryIndicator, fontSize: 45 }}
              />
            </Mui.Grid>
            <Mui.Grid xs={8} sm={8} md={7} lg={7} xl={6} sx={{ paddingTop: 1 }}>
              <Mui.Stack>
                <Mui.Typography>{fileName}</Mui.Typography>
                <Mui.Typography>{fileSize}</Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              xs={2}
              sm={2}
              md={2.5}
              lg={2.5}
              xl={3}
              sx={{ paddingTop: 1, paddingLeft: 1 }}
            >
              <Mui.IconButton onClick={() => clearImportedData()}>
                <Cancel
                  fontSize="small"
                  sx={{ color: themes.blackColor, fontSize: 45 }}
                />
              </Mui.IconButton>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>

        <Mui.Grid
          xs={2}
          sm={2}
          md={9.5}
          lg={9}
          xl={9}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          <Mui.Button
            variant="contained"
            style={{
              alignSelf: "flex-end",
              marginRight: 20.0,
              width: 150,
              backgroundColor: buttonDisabled
                ? themes.shadowColor
                : themes.primaryIndicator,
              color: themes.headLine,
              textTransform: "capitalize",
            }}
            onClick={() => CreateUsers()}
            disabled={buttonDisabled}
          >
            <Mui.Typography sx={{ fontSize: { xs: 0, sm: 0, md: 12, lg: 14 } }}>
              Create Users
            </Mui.Typography>
          </Mui.Button>
        </Mui.Grid>
      </Mui.Grid>
      {!loading ? (
        <Mui.TableContainer
          component={Mui.Paper}
          sx={{ width: "100%", maxHeight: 840, paddingRight: 1 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                maxWidth="xl"
                align="left"
                sx={{
                  "& .MuiTableCell-root": {
                    height: "15px",
                    padding: "6px 6px",
                  },
                }}
              >
                {header.map((header) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {header}
                  </Mui.TableCell>
                ))}
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Status
                </Mui.TableCell>
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  Actions
                </Mui.TableCell>
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {importedData?.length === 0 ? (
                <Mui.TableRow>
                  <Mui.TableCell colSpan={header.length + 2} align="center">
                    <Mui.Alert severity="info">No data available</Mui.Alert>
                  </Mui.TableCell>
                </Mui.TableRow>
              ) : (
                importedData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <Mui.TableRow
                      key={data.id}
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "15px",
                          padding: "6px 6px",
                        },
                        backgroundColor:
                          data?.status === "Invalid"
                            ? themes.backgroundRed
                            : null,
                      }}
                    >
                      <Mui.TableCell align="left">
                        {data?.employee_id ? data?.employee_id : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.user_name ? data?.user_name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.phone_number ? data?.phone_number : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.department_name ? data?.department_name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.date_joined
                          ? data?.date_joined?.split("-").reverse().join("-")
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.designation_name
                          ? data?.designation_name
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.employment_type_name
                          ? data?.employment_type_name
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.leave_group_name
                          ? data?.leave_group_name
                          : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        {data?.company_name ? data?.company_name : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {data?.status ? data?.status : "N/A"}
                      </Mui.TableCell>
                      <Mui.TableCell align="left">
                        <Mui.Stack direction="row">
                          <Mui.IconButton
                            onClick={() => {
                              setEditEmployeeDetails(data);
                              setOpenSidePeek(true);
                              setReloadData(true);
                            }}
                          >
                            <Edit />
                          </Mui.IconButton>
                          <Mui.IconButton
                            onClick={() => {
                              setDeleteUser(data);
                              setRemoveDialogBox(true);
                            }}
                          >
                            <Delete />
                          </Mui.IconButton>
                          {data?.status === "Invalid" ? (
                            <Mui.Tooltip title={data.error_messages}>
                              <Mui.IconButton>
                                <Info />
                              </Mui.IconButton>
                            </Mui.Tooltip>
                          ) : null}
                        </Mui.Stack>
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ))
              )}
            </Mui.TableBody>
          </Mui.Table>
          {importedData.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={importedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.TableContainer>
      ) : (
        <Loader info="Loading..." />
      )}
      <GlobalDialogBox
        handleCloseDialog={() => {
          setRemoveDialogBox(false);
        }}
        open={removeDialogBox}
        title="Remove User"
      >
        <Mui.Typography>
          Do you want to remove the employee :{" "}
          <span style={{ color: themes.redColor }}>{deleteUser.user_name}</span>{" "}
          ?
        </Mui.Typography>

        <Mui.Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <CustomButton
            actionFuntion={() => {
              setRemoveDialogBox(false);
            }}
            actionName="No"
            typeName="button"
          />{" "}
          &nbsp;&nbsp;
          <CustomButton
            actionFuntion={setDeleteUserDetails}
            actionName="Yes"
            typeName="button"
          />
        </Mui.Grid>
      </GlobalDialogBox>
      {openSidePeek ? (
        <EditUserDetails
          openSidePeek={openSidePeek}
          setBulkData={setBulkData}
          setOpenSidePeek={setOpenSidePeek}
          editEmployeeDetails={editEmployeeDetails}
          reloadData={reloadData}
          setReloadData={setReloadData}
          importedData={importedData}
          setImportData={setImportData}
          setButtonDisabled={setButtonDisabled}
        />
      ) : null}
    </Mui.Grid>
  );
};
