import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";

const keys = [
  "status",
  "user_name",
  "repayment_date",
  "next_repayment_date",
  "amount",
  "advance_type",
  "description",
  "reason",
];

export const History = ({
  startDate,
  endDate,
  setLeaveChange,
  leaveChange,
}) => {
  const { domain, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [loanData, setLoanData] = useState([]);
  const [updateData, setUpdateData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    result: advanceSalaryDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(
    `${domain}advances/?selected_user_id=${userDetails?.id}&start_date=${startDate}&end_date=${endDate}`
  );

  useEffect(() => {
    if (leaveChange === true) {
      reCallApi(true);
      setLeaveChange(false);
    }
  }, [leaveChange, reCallApi, setLeaveChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const advanceSalaryHeader = [
    { name: "" },
    { name: "Date" },
    { name: "Advance Type" },
    { name: "Advance Amount" },
    { name: "Reason for Cash" },
    { name: "Status" },
  ];

  useEffect(() => {
    if (
      updateData &&
      advanceSalaryDetails?.length !== 0 &&
      advanceSalaryDetails
    ) {
      setLoanData(advanceSalaryDetails);
      setUpdateData(false);
    }
  }, [advanceSalaryDetails, updateData]);

  useEffect(() => {
    const filteredResults = advanceSalaryDetails?.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setLoanData(filteredResults);
    setPage(0);
  }, [globalSearchValue, advanceSalaryDetails]);

  return loading === true ? (
    <Loader info="Loading..." />
  ) : (
    <React.Fragment>
      <Mui.TableContainer
        sx={{
          marginTop: 2,
          marginLeft: "auto",
          marginRight: "auto",
          overflow: "auto",
        }}
        className="w-96 border rounded"
      >
        <Mui.Table className="border">
          <Mui.TableHead
            sx={{
              backgroundColor: themes.primary,
            }}
          >
            <Mui.TableRow>
              {advanceSalaryHeader?.map((value) => (
                <Mui.TableCell
                  sx={{ color: themes.blackColor, fontWeight: "bold" }}
                >
                  {value?.name}
                </Mui.TableCell>
              ))}
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            {loanData?.length === 0 ? (
              <Mui.TableRow>
                <Mui.TableCell colSpan={8}>
                  <Mui.Alert severity="info" sx={{ margin: 2, width: "100%" }}>
                    No data available
                  </Mui.Alert>
                </Mui.TableCell>
              </Mui.TableRow>
            ) : (
              loanData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((value) => (
                  <Mui.TableRow>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell>
                      {value?.repayment_date ? value?.repayment_date : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.advance_type ? value?.advance_type : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.amount ? value?.amount : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.description ? value?.description : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.status ? value?.status : "N/A"}
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))
            )}
          </Mui.TableBody>
        </Mui.Table>
      </Mui.TableContainer>

      {loanData?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={loanData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
