import React, { useState } from "react";
import { themes } from "services/constants";
import * as Mui from "@mui/material";

import { overTime } from "pages/PayRoll/PayRollHeaders";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
export const General = () => {
  const [overTimeName, setOverTimeName] = useState("");
  const [rate, setRate] = useState(null);

  const handleOverTimeName = (event, data) => {
    if (data) {
      setOverTimeName(data.label);
    }
  };

  const updateRateDetails = (event) => {
    if (event.target.value) {
      setRate(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingRight: 1, paddingTop: 2 }}>
        <Mui.Typography
          sx={{ fontSize: 25, paddingLeft: 1, fontWeight: "bold" }}
        >
          Leaves
        </Mui.Typography>
        <Mui.Grid container>
          <Mui.Grid xs={8}>
            <Mui.Typography
              sx={{ fontSize: 20, paddingLeft: 3, fontWeight: "bold" }}
            >
              Unapproved Leave/Absent
            </Mui.Typography>
            <Mui.Typography
              sx={{ paddingLeft: 3, color: themes.generalSubHeader }}
            >
              No.of days salary to be deducted for each Absent
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <CustomTextBox
              selectedValue={rate}
              updateDataFunction={updateRateDetails}
            />
          </Mui.Grid>
          <Mui.Grid xs={2} sx={{ paddingTop: 2.5 }}>
            <Mui.Typography
              xs={{
                fontSize: 25,
                paddingLeft: 2,
                paddingTop: 3,
                color: themes.generalSubHeader,
              }}
            >
              Days
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingButtom: 1, paddingTop: 2 }}>
          <Mui.Grid xs={8}>
            <Mui.Typography
              sx={{ fontSize: 20, paddingLeft: 3, fontWeight: "bold" }}
            >
              Exceeding Approved Leaves
            </Mui.Typography>
            <Mui.Typography
              sx={{ paddingLeft: 3, color: themes.generalSubHeader }}
            >
              No.of days salary to be deducted for each Absent
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <CustomTextBox
              selectedValue={rate}
              updateDataFunction={updateRateDetails}
            />
          </Mui.Grid>
          <Mui.Grid xs={2} sx={{ paddingTop: 2.5 }}>
            <Mui.Typography
              xs={{
                fontSize: 25,
                paddingLeft: 2,
                color: themes.generalSubHeader,
              }}
            >
              Days
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container sx={{ paddingRight: 1, paddingTop: 2 }}>
        <Mui.Typography
          sx={{ fontSize: 25, paddingLeft: 1, fontWeight: "bold" }}
        >
          Over Time
        </Mui.Typography>
        <Mui.Grid container sx={{ paddingLeft: 3 }}>
          <Mui.Grid xs={1.5}>
            <Mui.Typography sx={{ paddingTop: 1 }}>
              For each overtime
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <CustomAutoComplete
              dataList={overTime}
              selectedValue={overTimeName}
              updateDataFunction={handleOverTimeName}
              placeHolder="Select hours/days"
            />
          </Mui.Grid>
          <Mui.Grid xs={0.5}>
            <Mui.Typography sx={{ paddingLeft: 1, paddingTop: 1 }}>
              rate ={" "}
            </Mui.Typography>
          </Mui.Grid>
          <Mui.Grid xs={1}>
            <CustomTextBox
              selectedValue={rate}
              updateDataFunction={updateRateDetails}
            />
          </Mui.Grid>
          <Mui.Grid xs={2}>
            <Mui.Typography sx={{ paddingLeft: 1, paddingTop: 1 }}>
              times standard rate
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
