import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { format, parse } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { showToast } from "services/utils/Status";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import * as Mui from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { CalendarMonth } from "@mui/icons-material";
import dayjs from "dayjs";
// import { format, parse } from "date-fns";
import sessionHandling from "services/utils/notificationUtils";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import moment from "moment";
export const HrmsPermission = () => {
  // const leaveDate = [];
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState,
    trigger,
    setValue,
  } = useForm({
    mode: "onBlur",
    start_time: null,
    end_time: null,
  });
  const { errors } = formState;
  const [employeeNameOptions, setEmployeeOptions] = useState([]);
  const [startTimeSelected, setStartTimeSelected] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [leaveStartDate, setleaveStartDate] = useState("");
  const [leaveEndDate, setleaveEndDate] = useState("");
  const [leaveDate, setLeaveDate] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState("");
  const [filteredValue, setFilteredValue] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmit = async (data) => {
    const formatTime = (time) => {
      return `${String(time.$H).padStart(2, "0")}:${String(time.$m).padStart(
        2,
        "0"
      )}:${String(time.$s).padStart(2, "0")}`;
    };

    const startTime = data.time_range[0]
      ? formatTime(data.time_range[0])
      : null;
    const endTime = data.time_range[1] ? formatTime(data.time_range[1]) : null;
    const currentUserDetails = employeeNameOptions.filter((emp) => {
      return emp.user_name === data.user_name;
    });
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}create_permission/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          date: data.from_date,
          description: data.description,
          user_id: currentUserDetails[0].user_id,
          applied_date: new Date().toISOString().split("T")[0],
          start_time: startTime,
          end_time: endTime,
          action: "Approved",
          approver_id: userDetails.id,
          approver_name: userDetails.username,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast(
          "success",
          `You created ${currentUserDetails[0].user_name} leave permission on ${startTime} to ${endTime} and it has been approved.`
        );
        setButtonLoader(false);
        if (
          new Date(leaveStartDate).getFullYear() === new Date().getFullYear() ||
          new Date(leaveEndDate).getFullYear() === new Date().getFullYear()
        ) {
          const today = dayjs().format("YYYY-MM-DD");
          setStartDate(today);
        } else {
          setStartDate(leaveStartDate);
        }
        reset();
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    const fetchLeaveDate = async () => {
      try {
        const response = await fetch(`${domain}leave-years/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const leaveDate = res.map((value) => ({
            label: value.name,
            start_date: value.start_date,
            end_date: value.end_date,
            filteredValue: `${value.start_date} - ${value.end_date}`,
          }));
          const today = dayjs().format("YYYY-MM-DD");
          const sDate = formatDate(res[0]?.start_date);
          const eDate = formatDate(res[0]?.end_date);
          setLeaveDate(leaveDate);
          setFilteredLabel(res[0]?.name);
          setFilteredValue(`${res[0]?.start_date} - ${res[0]?.end_date}`);
          setleaveStartDate(sDate);
          setleaveEndDate(eDate);
          if (
            new Date(
              res[0]?.start_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear() ||
            new Date(
              res[0]?.end_date.split("-").reverse().join("-")
            ).getFullYear() === new Date().getFullYear()
          ) {
            setStartDate(today);
          } else {
            setStartDate(sDate);
          }
          setValue("from_date", today);
          setValue("to_date", today);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchLeaveDate();
  }, [domain, setValue, token]);

  const validateTime = (timeString) => {
    const timeFormatRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;

    if (!timeString.match(timeFormatRegex)) {
      return "Please enter a valid time with AM or PM.";
    }

    return true;
  };

  const validateTimeRange = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return "Both start and end times are required.";
    }
    const startTimeString = startTime.format("hh:mm A");
    const endTimeString = endTime.format("hh:mm A");

    const startTimeValid = validateTime(startTimeString);
    const endTimeValid = validateTime(endTimeString);

    if (startTimeValid !== true) {
      return startTimeValid;
    }

    if (endTimeValid !== true) {
      return endTimeValid;
    }

    const start = dayjs(startTime);
    const end = dayjs(endTime);

    if (start.isAfter(end)) {
      return "End time cannot be before start time.";
    }

    const duration = end.diff(start, "hour", true);
    if (duration > 2) {
      return "Duration cannot be more than 2 hours.";
    }

    return true;
  };

  const formatDate = (dateString) => {
    const date = parse(dateString, "dd-MM-yyyy", new Date());
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
  };

  const resetForm = () => {
    reset();
    const today = dayjs().format("YYYY-MM-DD");
    setStartDate(today);
    setValue("from_date", today);
    setValue("to_date", today);
  };

  const handleClearButtonClick = () => {
    resetForm();
  };

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await fetch(
          `${domain}list/employee?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          Array.isArray(setEmployeeOptions(res));
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchEmployeeName();
  }, [domain, token, userDetails.id]);

  const handleChangeLeavePeriod = (event, value) => {
    const today = dayjs().format("YYYY-MM-DD");
    if (value && value.filteredValue) {
      setFilteredLabel(value.label);
      setFilteredValue(value.filteredValue);
      setStartDate(formatDate(value.start_date));
      setleaveStartDate(formatDate(value.start_date));
      setleaveEndDate(formatDate(value.end_date));
      if (
        new Date(value.start_date).getFullYear() === new Date().getFullYear() ||
        new Date(
          value.end_date.split("-").reverse().join("-")
        ).getFullYear() === new Date().getFullYear()
      ) {
        let startDateConvertions = moment(new Date()).format("DD-MM-YYYY");
        setStartDate(formatDate(startDateConvertions));
        setValue("from_date", formatDate(startDateConvertions));
        setValue("to_date", formatDate(startDateConvertions));
      } else {
        setStartDate(formatDate(value.start_date));
        setValue("from_date", formatDate(value.start_date));
        setValue("to_date", formatDate(value.end_date));
      }
    } else {
      setFilteredLabel("");
      setFilteredValue("");
      setleaveStartDate("");
      setleaveEndDate("");
      setFilteredLabel("");
      setFilteredValue("");
      setleaveStartDate(today);
      setleaveEndDate(today);
      setStartDate(today);
    }
  };

  return (
    <>
      <Card
        content="center"
        sx={{
          marginLeft: 30,
          marginRight: 30,
          boxShadow: 5,
          borderRadius: 5,
          marginTop: 7,
          height: "100%",
        }}
      >
        <CardContent sx={{ marginTop: 2 }}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Mui.Grid
              container
              xs={12}
              sx={{
                justifyContent: "flex-end",
                paddingTop: 1,
                marginRight: 21,
              }}
            >
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={
                  leaveDate?.find(
                    (option) => option?.filteredValue === filteredValue
                  ) || ""
                }
                options={leaveDate}
                style={{
                  width: 305,
                }}
                getOptionLabel={(option) =>
                  option?.filteredValue ?? filteredValue ?? ""
                }
                isOptionEqualToValue={(option, value) =>
                  option?.filteredValue === value?.filteredValue
                }
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.filteredValue
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.filteredValue}</li>;
                }}
                ListboxProps={{
                  style: {
                    maxHeight: 150,
                  },
                }}
                onChange={handleChangeLeavePeriod}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    sx={{ background: themes.whiteColor }}
                    placeholder="Select leave period"
                    label={filteredLabel ? filteredLabel : "Leave Period"}
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <CalendarMonth
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Mui.Grid>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="user_name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Choose employee name.",
                  }}
                  render={({ field }) => (
                    <>
                      <Typography
                        sx={{ marginTop: 4, marginLeft: 25 }}
                        style={{
                          fontSize: 20,
                        }}
                      >
                        Employee Name
                      </Typography>
                      <Mui.Autocomplete
                        {...field}
                        options={employeeNameOptions}
                        getOptionLabel={(option) => option.user_name || ""}
                        onChange={(event, newValue) =>
                          field.onChange(newValue?.user_name || "")
                        }
                        value={
                          employeeNameOptions.find(
                            (option) => option.user_name === field.value
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select employee name *"
                            variant="outlined"
                            error={!!errors.user_name}
                            helperText={
                              errors.user_name && errors.user_name.message
                            }
                            sx={{
                              marginTop: 1,
                              width: 280,
                              marginLeft: 25,
                              marginRight: "auto",
                            }}
                          />
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: 200,
                          },
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{ marginTop: 4, marginLeft: 13 }}
                  htmlFor="from_date"
                  style={{
                    fontSize: 20,
                  }}
                >
                  Date
                </Typography>

                <TextField
                  sx={{
                    marginTop: 1,
                    width: 280,
                    marginLeft: 13,
                  }}
                  size="medium"
                  type="date"
                  id="from_date"
                  value={startDate}
                  inputProps={{
                    min: leaveStartDate,
                    max: leaveEndDate,
                  }}
                  {...register("from_date", {
                    required: "Date is required.",
                    validate: validateStartDate,
                  })}
                  error={!!errors.from_date}
                  helperText={errors.from_date && errors.from_date.message}
                  onChange={(event) => {
                    setStartDate(event.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={5.5}>
                <Typography
                  sx={{ marginTop: 3, marginLeft: 25 }}
                  style={{ fontSize: 20 }}
                >
                  Start Time
                </Typography>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                <Typography
                  sx={{ marginTop: 3, marginLeft: 7 }}
                  style={{ fontSize: 20 }}
                >
                  End Time
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="time_range"
                  rules={{
                    validate: (value) => {
                      if (!value || value[0] === null || value[1] === null) {
                        return "Both start and end times are required.";
                      }
                      const startTime = dayjs(value[0]);
                      const endTime = dayjs(value[1]);
                      return validateTimeRange(startTime, endTime);
                    },
                  }}
                  control={control}
                  defaultValue={[null, null]}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <Grid xs={7} sx={{ paddingLeft: 25, paddingTop: 0.5 }}>
                        <TimePicker
                          sx={{ width: 280 }}
                          label="Start Time"
                          value={value[0]}
                          onChange={(newValue) => {
                            onChange([newValue, value[1]]);
                            setStartTimeSelected(true);
                          }}
                          error={!!error}
                          helperText={error ? error.message : null}
                          TextFieldComponent={TextField}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                        />
                        {error && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Grid>
                      <Grid xs={5} sx={{ paddingLeft: 3, paddingTop: 0.5 }}>
                        <TimePicker
                          sx={{ width: 280 }}
                          label="End Time"
                          value={value[1]}
                          onChange={(newValue) =>
                            onChange([value[0], newValue])
                          }
                          error={!!error}
                          helperText={error ? error.message : null}
                          TextFieldComponent={TextField}
                          onBlur={() => {
                            trigger("time_range");
                          }}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disabled={!startTimeSelected}
                        />
                        {error && (
                          <Typography
                            variant="body2"
                            color="error"
                            sx={{ fontSize: 13, paddingLeft: 1.5 }}
                          >
                            {error.message}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <br></br>

            <Typography
              sx={{ marginTop: 0.5, marginLeft: 25 }}
              htmlFor="description"
              style={{
                fontSize: 20,
              }}
            >
              Reason for Permission
            </Typography>
            <TextField
              sx={{
                marginTop: 1,
                width: "68.5%",
                marginLeft: 25,
                marginRight: "auto",
              }}
              id="description"
              {...register("description", {
                required: "description is required",
              })}
              label="Provide a reason for your time off"
              multiline
              minRows={3}
            />

            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item xs={5.5}></Grid>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={handleClearButtonClick}
                  actionName="Clear"
                  typeName="button"
                />{" "}
                &nbsp;
                <CustomButton
                  actionName="Approve"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
};
