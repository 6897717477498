import React, { useState, useEffect, useCallback } from "react";
import { Approve } from "./Approve";
import { History } from "./History";
import { Approved } from "./Reject";
import { DateRangePicker } from "./DateRangePicker";
import * as Mui from "@mui/material";
import moment from "moment";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
import { showToast } from "services/utils/Status";
import { useSelector, useDispatch } from "react-redux";
import { themes, defaultAllSelection } from "services/constants";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import sessionHandling from "services/utils/notificationUtils";

const overTimeKeyPair = [
  {
    key: "checkbox",
    name: "",
  },
  {
    key: "user_name",
    name: "Employee name",
  },
  {
    key: "date",
    name: "Date",
  },
  {
    key: "shift_name",
    name: "Shift Name",
  },
  {
    key: "work_hours",
    name: "Working Hours",
  },
  {
    key: "total_shift_time",
    name: "Shift Time",
  },
  {
    key: "total_break_time",
    name: "Break",
  },
  {
    key: "overtime",
    name: "Over Time",
  },

  {
    key: "action",
    name: "Action",
  },
];

export const OverTime = ({ setReloadData }) => {
  const dispatch = useDispatch();
  const { domain, token, userDetails, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );

  const [overTimeScreen, setOverTimeScreen] = useState("Approve");
  const [departmentData, setDepartmentData] = useState([]);
  const [initialRun, setInitialRun] = useState(true);
  const [pendingDetails, setPendingDetails] = useState([]);
  const [listViewPage, setListViewPage] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchEndDate, setSearchEndDate] = useState("");
  const [overTimeDetails, setOverTimeDetails] = useState([]);

  const handleStartDateAndEnd = useCallback(
    async (startDate, endDate) => {
      setSelectedDateRange(`${startDate} - ${endDate}`);
      setSearchStartDate(startDate.split("/").reverse().join("-"));
      setSearchEndDate(endDate.split("/").reverse().join("-"));
      try {
        const response = await fetch(
          `${domain}over-time-hours/?user_id=${userDetails.id}&department_id=99999`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const combinedData = res.map((item) => ({
            ...item,
            overtime_period: `${item.check_in || "N/A"} to ${
              item.check_out || "N/A"
            }`,
          }));
          setOverTimeDetails(combinedData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        console.log(error);
        // showToast("error", error.message);
      }
    },
    [
      domain,
      setOverTimeDetails,
      setSearchEndDate,
      setSearchStartDate,
      setSelectedDateRange,
      token,
      userDetails.id,
    ]
  );

  useEffect(() => {
    if (initialRun) {
      dispatch(setDepartmentName("All"));
      dispatch(setDepartmentId(99999));
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );

      setSelectedDateRange(
        `${moment(
          new Date(mostRecentMonday.toISOString().split("T")[0])
        ).format("DD/MM/YYYY")} - ${moment(
          new Date(currentWeekSunday.toISOString().split("T")[0])
        ).format("DD/MM/YYYY")}`
      );
      handleStartDateAndEnd(
        moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
          "DD/MM/YYYY"
        ),
        moment(new Date(currentWeekSunday.toISOString().split("T")[0])).format(
          "DD/MM/YYYY"
        )
      );
      setInitialRun(false);
    }
  }, [dispatch, handleStartDateAndEnd, initialRun, setSelectedDateRange]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(
          `${domain}get-department/?user_id=${userDetails.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department_name: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token, userDetails.id]);

  const handleChange = (event, newAlignment) => {
    setOverTimeScreen(newAlignment);
  };
  const renderComponent = () => {
    switch (overTimeScreen) {
      case "Approve":
        return (
          <Approve
            overTimeDetails={overTimeDetails}
            listViewPage={listViewPage}
            setListViewPage={setListViewPage}
            refreshReCall={refreshReCall}
            overTimeKeyPair={overTimeKeyPair}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            setReloadData={setReloadData}
          />
        );
      case "History":
        return <History startDate={searchStartDate} endDate={searchEndDate} />;
      case "Reject":
        return <Approved startDate={searchStartDate} endDate={searchEndDate} />;
      default:
        break;
    }
  };
  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentId(data.id));
      dispatch(setDepartmentName(data.label));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      if (overTimeScreen === "Approve") {
        handleStartDate(
          searchStartDate.split("-").reverse().join("/"),
          searchEndDate.split("-").reverse().join("/"),
          data.id
        );
      }
      setReloadData(true);
      setSelectAll(false);
    }
  };

  const handleStartDate = async (startDate, endDate, id) => {
    if (overTimeScreen === "Approve") {
      setSelectedDateRange(`${startDate} - ${endDate}`);
      setSearchStartDate(startDate.split("/").reverse().join("-"));
      setSearchEndDate(endDate.split("/").reverse().join("-"));
      try {
        const response = await fetch(
          `${domain}over-time-hours/?user_id=${userDetails.id}&department_id=${
            id ? id : departmentId
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const combinedData = res.map((item) => ({
            ...item,
            overtime_period: `${item.check_in || "N/A"} to ${
              item.check_out || "N/A"
            }`,
          }));
          setOverTimeDetails(combinedData);
          setPendingDetails(combinedData);
          setSelectAll(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    } else {
      setSelectedDateRange(`${startDate} - ${endDate}`);
      setSearchStartDate(startDate.split("/").reverse().join("-"));
      setSearchEndDate(endDate.split("/").reverse().join("-"));
    }
  };

  const refreshReCall = async () => {
    try {
      const response = await fetch(
        `${domain}over-time-hours/?user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}&department_id=${departmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        const combinedData = res.map((item) => ({
          ...item,
          overtime_period: `${item.check_in || "N/A"} to ${
            item.check_out || "N/A"
          }`,
        }));
        setOverTimeDetails(combinedData);
        setPendingDetails(combinedData);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  useEffect(() => {
    const searchFields = [
      "user_name",
      "date",
      "shift_name",
      "overtime_start",
      "overtime_end",
      "overtime",
    ];
    const filteredResults = pendingDetails.filter((item) =>
      searchFields.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setOverTimeDetails(filteredResults);
    setListViewPage(0);
  }, [globalSearchValue, pendingDetails, setOverTimeDetails]);

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 5, paddingLeft: 3 }}>
        <Mui.Grid xs={4.4}>
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: 500,
              height: 55,
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Mui.ToggleButtonGroup
              value={overTimeScreen}
              exclusive
              onChange={handleChange}
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: "30px",
                backgroundColor: themes.lightBrown,
              }}
            >
              <Mui.ToggleButton
                value="Approve"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Approve"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "Approve"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Approve"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Approve"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
                onClick={() => {
                  handleStartDate(
                    searchStartDate.split("-").reverse().join("/"),
                    searchEndDate.split("-").reverse().join("/"),
                    departmentId,
                    setReloadData(true),
                    refreshReCall()
                  );
                }}
              >
                Approve
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="History"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "History"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "History"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "History"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "History"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                History
              </Mui.ToggleButton>
              <Mui.ToggleButton
                value="Reject"
                sx={{
                  flex: 1,
                  textTransform: "capitalize",
                  fontSize: 18,
                  borderRadius: "30px",
                  fontWeight: 600,
                  border: "none",
                  color:
                    overTimeScreen === "Reject"
                      ? themes.whiteColor
                      : themes.blackColor,
                  backgroundColor:
                    overTimeScreen === "Reject"
                      ? themes.blackColor
                      : themes.lightBrown,
                  "&.Mui-selected": {
                    backgroundColor: themes.blackColor,
                    color: themes.whiteColor,
                    "&:hover": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                    },
                    borderRadius: "30px",
                    "&.Mui-selected": {
                      borderRadius: "30px",
                    },
                  },
                  "&:hover": {
                    backgroundColor:
                      overTimeScreen === "Reject"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Reject"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                  "&:focus": {
                    backgroundColor:
                      overTimeScreen === "Reject"
                        ? themes.blackColor
                        : themes.lightBrown,
                    color:
                      overTimeScreen === "Reject"
                        ? themes.whiteColor
                        : themes.blackColor,
                  },
                }}
              >
                Rejected
              </Mui.ToggleButton>
            </Mui.ToggleButtonGroup>
          </Mui.Box>
        </Mui.Grid>
        <Mui.Grid xs={2.5}></Mui.Grid>
        {overTimeScreen === "Approve" ? <Mui.Grid xs={2.5}></Mui.Grid> : null}
        <Mui.Grid item xs={2.4} sx={{ padding: 1 }}>
          <CustomAutoComplete
            dataList={departmentData?.map((obj) => ({
              ...obj,
              label: obj.department_name,
            }))}
            selectedValue={departmentName}
            updateDataFunction={updateDepartmentName}
            placeHolder="Select department name"
            label="Select Department Name"
          />
        </Mui.Grid>
        <Mui.Grid xs={2.4} sx={{ paddingTop: 1 }}>
          {overTimeScreen === "Approve" ? null : (
            <DateRangePicker
              handleStartDate={handleStartDate}
              selectedDateRange={selectedDateRange}
            />
          )}
        </Mui.Grid>
        <Mui.Grid container>{renderComponent()}</Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
