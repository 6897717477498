import { showToast } from "services/utils/Status";

export default function sessionHandling() {
  localStorage.clear();
  showToast(
    "error",
    "You have logged in from another device, so this session will be signed out.",
    {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );

  // Redirect to login after a delay
  setTimeout(() => {
    window.location.href = "/auth/login"; // Adjust the path as per your routing setup
  }, 2000);
}
