import { createSlice } from "@reduxjs/toolkit";

export const hrmsManagementReducer = createSlice({
  name: "token",
  initialState: {
    editProject: null,
    priorityFilter: false,
    tabIndicatorValue: 0,
    calendarDepartmentId: JSON.parse(
      localStorage.getItem("hrmsCalendarDepartmentId")
    )
      ? JSON.parse(localStorage.getItem("hrmsCalendarDepartmentId"))
      : 99999,
    calendarDepartmentName: localStorage.getItem("hrmsCalendarDepartmentName")
      ? localStorage.getItem("hrmsCalendarDepartmentName")
      : "All",
    approveLeaveDepartmentName: localStorage.getItem(
      "approveLeaveDepartmentName"
    )
      ? localStorage.getItem("approveLeaveDepartmentName")
      : "All",
    approveLeaveDepartmentId: JSON.parse(
      localStorage.getItem("approveLeaveDepartmentId")
    )
      ? JSON.parse(localStorage.getItem("approveLeaveDepartmentId"))
      : 99999,
    editEmployeeId: JSON.parse(localStorage.getItem("editEmployeeIdNumber"))
      ? JSON.parse(localStorage.getItem("editEmployeeIdNumber"))
      : null,
    editEmployee: JSON.parse(localStorage.getItem("editEmployeeDetails"))
      ? JSON.parse(localStorage.getItem("editEmployeeDetails"))
      : null,
    employeeScreenAction: "",
    planShiftScreen: localStorage.getItem("planShiftScreen")
      ? localStorage.getItem("planShiftScreen")
      : "Monthly",
    teamZoneSettingScreen: localStorage.getItem("settingScreens")
      ? localStorage.getItem("settingScreens")
      : "Leave Period",
    approveSettingsScreens: localStorage.getItem("approveSettingScreens")
      ? localStorage.getItem("approveSettingScreens")
      : "Leaves",
  },
  reducers: {
    setEditProject: (state, action) => {
      state.editProject = action.payload;
    },
    setPriorityFilter: (state, action) => {
      state.priorityFilter = action.payload;
    },
    setTabIndicatorValue: (state, action) => {
      state.tabIndicatorValue = action.payload;
    },
    setDepartmentId: (state, action) => {
      state.departmentId = action.payload;
    },
    setDepartmentLeadId: (state, action) => {
      state.departmentLeadId = action.payload;
    },
    setUpdateCalendarDepartmentId: (state, action) => {
      state.calendarDepartmentId = action.payload;
    },
    setUpdateCalendarDepartmentName: (state, action) => {
      state.calendarDepartmentName = action.payload;
    },
    setApproveLeaveDepartmentName: (state, action) => {
      state.approveLeaveDepartmentName = action.payload;
    },
    setApproveLeaveDepartmentId: (state, action) => {
      state.approveLeaveDepartmentId = action.payload;
    },
    setEditEmployee: (state, action) => {
      state.editEmployee = action.payload;
    },
    setEditEmployeeID: (state, action) => {
      state.editEmployeeId = action.payload;
    },
    setEmployeeScreenAction: (state, action) => {
      state.employeeScreenAction = action.payload;
    },
    setPlanShiftScreen: (state, action) => {
      state.planShiftScreen = action.payload;
    },
  },
});

export const {
  setEditProject,
  setPriorityFilter,
  setTabIndicatorValue,
  setDepartmentId,
  setDepartmentLeadId,
  setUpdateCalendarDepartmentId,
  setUpdateCalendarDepartmentName,
  setApproveLeaveDepartmentName,
  setApproveLeaveDepartmentId,
  setEditEmployee,
  setEditEmployeeID,
  setEmployeeScreenAction,
  setPlanShiftScreen,
} = hrmsManagementReducer.actions;

export default hrmsManagementReducer.reducer;
