import React, { useEffect, useState } from "react";
import { themes } from "services/constants";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { siteSettingsEditToastMessage } from "services/constants";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  styled,
  TextField,
  Typography,
  Grid,
  Radio,
  FormControlLabel,
  useRadioGroup,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import sessionHandling from "services/utils/notificationUtils";
const nullCheck = null;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: themes.blackColor }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

FuncDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const SiteSettingsPopup = ({
  handleClose,
  open,
  title,
  editLocationDetails,
  setUpdateEditData,
  updateEditData,
  setUpdate,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    trigger,
  } = useForm({
    mode: "onBlur",
  });
  const [radioButton, setRadioButton] = useState("Home");
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [buttonLoder, setButtonLoader] = useState(false);

  useEffect(() => {
    const updateSiteDetails = () => {
      if (editLocationDetails.length !== 0) {
        setValue("location_name", editLocationDetails.location_name);
        setValue("address", editLocationDetails.address);
        setValue("city", editLocationDetails.city);
        setValue("radius", editLocationDetails.radius);
        setValue("pincode", editLocationDetails.pincode);
        setRadioButton(
          editLocationDetails.tag === nullCheck
            ? "Home"
            : editLocationDetails.tag
        );
        setUpdateEditData(false);
      }
    };
    if (updateEditData) {
      updateSiteDetails();
    }
  }, [editLocationDetails, setUpdateEditData, setValue, updateEditData]);

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  const handleKeyPressWithPoint = (e) => {
    const charCode = e.charCode;
    if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}edit_location/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          location_id: editLocationDetails.id,
          location_status: null,
          city: data.city,
          location_name: data.location_name,
          address: data.address,
          radius: data.radius,
          pincode: data.pincode,
          tag: radioButton,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", siteSettingsEditToastMessage.editMessage);
        reset();
        setUpdate(true);
        handleClose(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const cancelEditOption = () => {
    handleClose(false);
    reset();
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <FuncDialogTitle
          id="customized-dialog-title"
          align="center"
          sx={{
            minWidth: 580,
            color: themes.headLine,
            fontWeight: 1000,
            fontSize: "23px",
            background: themes.primary,
          }}
          onClose={cancelEditOption}
          title={title}
        >
          {title}
        </FuncDialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Typography>Site Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter location name"
                {...register("location_name", {
                  required: "Location name is required",
                  maxLength: {
                    value: 30,
                    message: "Maximum length is 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Minium length is 3 characters",
                  },
                  validate: {
                    nameAvailable: async (fieldValue) => {
                      if (fieldValue.trim().length < 3) {
                        return "Site name must contain at least one alphabet character.";
                      } else if (
                        editLocationDetails.location_name !== fieldValue
                      ) {
                        const response = await fetch(
                          `${domain}check-location-name?location_name=${fieldValue.trim()}`,
                          {
                            method: "GET",
                            headers: {
                              "content-type": "application/json",
                              Authorization: `token ${token}`,
                            },
                          }
                        );
                        const resp = await response.json();
                        if (resp.exists) {
                          return "Site name is already exists";
                        }
                      }
                    },
                    noDigitsOrSpecial: (fieldValue) => {
                      if (!/[A-Za-z]/.test(fieldValue)) {
                        return "Site name must contain at least one alphabet character.";
                      }
                      if (/^\d+$/.test(fieldValue)) {
                        return "Site name should not contain only digits.";
                      }
                      if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                        return "Site name should only contain alphabets,spaces & numbers ";
                      }
                      return true;
                    },
                  },
                })}
                error={errors.name ? true : false}
                onBlur={() => trigger("location_name")}
                sx={{ paddingTop: 0.3 }}
              />
              {errors.location_name ? (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, paddingLeft: 0.5 }}
                >
                  {errors.location_name?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Typography>Address</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter Address"
                {...register("address", {
                  required: "Address is required",
                })}
                sx={{ paddingTop: 0.3 }}
              />
              {errors.address ? (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, paddingLeft: 0.5 }}
                >
                  {errors.address?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Typography>City</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter city"
                {...register("city", {
                  required: "City name is required",
                  maxLength: {
                    value: 32,
                    message: "Maximum length is 32 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Maximum length is 3 characters",
                  },
                })}
                InputProps={{
                  inputProps: { maxLength: 38 },
                }}
                sx={{ paddingTop: 0.3 }}
              />
              {errors.city ? (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, paddingLeft: 0.5 }}
                >
                  {errors.city?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Typography>Radius</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter radius"
                {...register("radius", {
                  required: "Radius is required",
                  min: { value: 20, message: "Minimum radius is 20 meter" },
                  max: { value: 100, message: "Maximum radius is 100 meter" },
                  validate: {
                    companyCodeAvailable: async (fieldValue) => {
                      if (fieldValue.trim() !== "") {
                        if (parseInt(fieldValue) > 100) {
                          return "Maximum radius is 100 meter";
                        } else if (parseInt(fieldValue) < 19) {
                          return "Minimum radius is 20 meter";
                        } else {
                          return true;
                        }
                      }
                    },
                  },
                })}
                onKeyPress={handleKeyPressWithPoint}
                InputProps={{
                  inputProps: { maxLength: 8 },
                }}
                sx={{ paddingTop: 0.3 }}
              />
              {errors.radius ? (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, paddingLeft: 0.5 }}
                >
                  {errors.radius?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid xs={12} sx={{ paddingBottom: 3 }}>
              <Typography>Pincode</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Enter pincode"
                {...register("pincode", {
                  required: "Pincode is required",
                  minLength: {
                    value: 6,
                    message: "Minimum length is 6 numbers",
                  },
                  maxLength: {
                    value: 7,
                    message: "Maximum length is 7 numbers",
                  },
                })}
                InputProps={{
                  inputProps: { maxLength: 7 },
                }}
                onKeyPress={handleKeyPress}
                sx={{ paddingTop: 0.3 }}
              />
              {errors.pincode ? (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{ fontSize: 13, paddingLeft: 0.5 }}
                >
                  {errors.pincode?.message}
                </Typography>
              ) : null}
            </Grid>
            <Grid container spacing={2} sx={{ paddingLeft: 3, paddingTop: 2 }}>
              <RadioGroup row name="use-radio-group" value={radioButton}>
                <Grid container spacing={2}>
                  <MyFormControlLabel
                    value="Home"
                    label="Home"
                    sx={{ paddingLeft: 2 }}
                    control={<Radio onClick={() => setRadioButton("Home")} />}
                  />
                  <MyFormControlLabel
                    value="Office"
                    label="Office"
                    sx={{ paddingLeft: 2 }}
                    control={<Radio onClick={() => setRadioButton("Office")} />}
                  />

                  <MyFormControlLabel
                    value="Site"
                    label="Site"
                    sx={{ paddingLeft: 2 }}
                    control={<Radio onClick={() => setRadioButton("Site")} />}
                  />
                </Grid>
              </RadioGroup>
            </Grid>
            <Grid container>
              <Grid xs={5}></Grid>
              <Grid
                xs={7}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <CustomButton
                  actionFuntion={() => cancelEditOption()}
                  actionName="Cancel"
                  typeName="button"
                />
                &nbsp;&nbsp;
                <CustomButton
                  actionName="Update"
                  typeName="submit"
                  disableAction={buttonLoder}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};
