import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import {
  onlyNumberRegex,
  numberWithDecimalRegex,
} from "services/constants/patterns";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { showToast } from "services/utils/Status";
import { AddCircleOutline, Close } from "@mui/icons-material";
import sessionHandling from "services/utils/notificationUtils";
import { Loader } from "components/Loader";
let id = 0;
let deductionId = 0;
export const EditAndSidePeek = ({
  openSidePeek,
  setOpenSidePeek,
  editUserDetails,
  companyId,
  reloadApi,
  viewPayrunDetails,
  payrunData,
}) => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  let {
    result: userPayRunDetails,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}payrun/details/?payroll_run_id=${editUserDetails.id}`);
  const { result: earningData, loading: loadEarnings } = useFetch(
    companyId !== 0 ? `${domain}earnings/?company=${companyId}` : null
  );
  const { result: deductionData, loading: loadDeductions } = useFetch(
    companyId !== 0
      ? `${domain}deductions/?company=${companyId}&deduction_type=F`
      : null
  );
  const [paidDays, setPaidDays] = useState(0);
  const [updateData, setUpdatedData] = useState(true);
  const [lopDays, setLopDays] = useState();
  const [doAnyAct, setDoAnyAct] = useState(false);
  //earning
  const [allEarningDetails, setAllEarningDetails] = useState([]);
  const [companyRemainingEarnings, setCompanyRemainingEarnings] = useState([]);
  const [earningStateUpdate, setEarningStateUpdate] = useState(true);
  const [earningTotalAmount, setEarningTotalAmount] = useState(0);
  //deduction
  const [allDeductionDetails, setAllDeductionDetails] = useState([]);
  const [
    companyRemainingDeductionDetails,
    setCompanyRemainingDeductionDetails,
  ] = useState([]);
  const [updateDeductionStates, setUpdateDeductionStates] = useState(true);
  const [deductionTotalAmount, setDeductionTotalAmount] = useState(0);
  const [lopError, setLopError] = useState("");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Mui.Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSidePeek(open);
    if (open === false) {
      if (doAnyAct) {
        setDoAnyAct(false);
      }
    }
  };
  //Lop Calculations

  useEffect(() => {
    if (userPayRunDetails && updateData) {
      let lop = userPayRunDetails.no_of_lop_days
        ? userPayRunDetails.no_of_lop_days
        : 0;
      let paidDays = userPayRunDetails?.actual_days_to_be_paid;
      setLopDays(
        userPayRunDetails.no_of_lop_days ? userPayRunDetails.no_of_lop_days : 0
      );
      setPaidDays(paidDays - lop);
    }
  }, [updateData, userPayRunDetails]);

  //Earnings
  useEffect(() => {
    if (
      !loadEarnings &&
      // earningData.length !== 0 &&
      userPayRunDetails.length !== 0 &&
      earningStateUpdate
    ) {
      let companyEarningDetails = earningData.filter((earn_data) => {
        return (
          earn_data.name !== "Basic" && earn_data.name !== "Special Allowance"
        );
      });
      let defaultEarningWithAction = userPayRunDetails?.earnings?.map(
        (element) => {
          return {
            ...element,
            action: "default",
          };
        }
      );
      let additionalEariningWithAction =
        userPayRunDetails?.additional_earnings.map((element) => {
          return {
            ...element,
            action: "additional",
          };
        });
      let allAddedEarnings = [
        ...defaultEarningWithAction,
        ...additionalEariningWithAction,
      ];
      let max = 0;
      allAddedEarnings.forEach((character) => {
        if (character.id > max) {
          max = character.id;
        }
      });
      id = max;
      setAllEarningDetails(allAddedEarnings);
      allAddedEarnings.forEach((element) => {
        companyEarningDetails = companyEarningDetails.filter((data) => {
          return data.name !== element.name;
        });
      });
      let totalEarningAmount = 0;
      allAddedEarnings.forEach((element) => {
        totalEarningAmount =
          totalEarningAmount +
          parseFloat(element.amount === "" ? 0 : element.amount);
      });
      setEarningTotalAmount(totalEarningAmount);
      setCompanyRemainingEarnings(companyEarningDetails);
      setEarningStateUpdate(false);
    }
  }, [
    allEarningDetails,
    earningData,
    earningStateUpdate,
    loadEarnings,
    userPayRunDetails?.additional_earnings,
    userPayRunDetails?.earnings,
    userPayRunDetails.length,
  ]);

  const AddEarning = async () => {
    let newEarningData = {
      id: id + 1,
      name: "",
      amount: 0,
      action: "add",
    };
    setAllEarningDetails([...allEarningDetails, newEarningData]);
    id = id + 1;
  };

  const updateEarningName = (data, id) => {
    let updateSalaryComponent = allEarningDetails?.map((element) => {
      if (element.id === id) {
        return {
          ...element,
          name: data.name,
          amount: 0,
        };
      } else {
        return element;
      }
    });
    setAllEarningDetails(updateSalaryComponent);
  };

  const onBlurToRemoveAlreadyUsedEarnings = () => {
    let companyEarningDetails = earningData.filter((earn_data) => {
      return (
        earn_data.name !== "Basic" && earn_data.name !== "Special Allowance"
      );
    });
    allEarningDetails.forEach((element) => {
      companyEarningDetails = companyEarningDetails.filter((data) => {
        return data.name !== element.name || element.action === "delete";
      });
    });
    setCompanyRemainingEarnings(companyEarningDetails);
  };

  const updateEarningAmount = (id) => (event) => {
    if (onlyNumberRegex.test(event.target.value)) {
      let updateSalaryComponent = allEarningDetails?.map((element) => {
        if (element.id === id) {
          if (element.action === "additional") {
            return {
              ...element,
              amount: event.target.value,
              action: "update",
            };
          } else {
            return {
              ...element,
              amount: event.target.value,
            };
          }
        } else {
          return element;
        }
      });
      setAllEarningDetails(updateSalaryComponent);
      let totalEarningAmount = 0;
      updateSalaryComponent.forEach((element) => {
        if (element.action !== "delete") {
          totalEarningAmount =
            totalEarningAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setEarningTotalAmount(totalEarningAmount);
    }
  };

  const removeAditionalEarning = (data) => {
    if (data.action === "update" || data.action === "additional") {
      let updateSalaryComponent = allEarningDetails?.map((element) => {
        if (element.id === data.id) {
          return {
            ...element,
            action: "delete",
          };
        } else {
          return element;
        }
      });
      setAllEarningDetails(updateSalaryComponent);
      //Caculations
      let companyEarningDetails = earningData.filter((earn_data) => {
        return (
          earn_data.name !== "Basic" && earn_data.name !== "Special Allowance"
        );
      });
      updateSalaryComponent.forEach((element) => {
        companyEarningDetails = companyEarningDetails.filter((data) => {
          return data.name !== element.name || element.action === "delete";
        });
      });
      setCompanyRemainingEarnings(companyEarningDetails);
      let totalEarningAmount = 0;
      updateSalaryComponent.forEach((element) => {
        if (element.action !== "delete") {
          totalEarningAmount =
            totalEarningAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setEarningTotalAmount(totalEarningAmount);
    } else {
      let updateEarning = allEarningDetails?.filter((fil_data) => {
        return fil_data.name !== data.name;
      });
      setAllEarningDetails(updateEarning);
      let companyEarningDetails = earningData.filter((earn_data) => {
        return (
          earn_data.name !== "Basic" && earn_data.name !== "Special Allowance"
        );
      });
      updateEarning.forEach((element) => {
        companyEarningDetails = companyEarningDetails.filter((data) => {
          return data.name !== element.name || element.action !== "delete";
        });
      });
      setCompanyRemainingEarnings(companyEarningDetails);
      let totalEarningAmount = 0;
      updateEarning.forEach((element) => {
        if (element.action !== "delete") {
          totalEarningAmount =
            totalEarningAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setEarningTotalAmount(totalEarningAmount);
    }
  };

  //Deductions

  useEffect(() => {
    if (
      // deductionData.length !== 0 &&
      !loadDeductions &&
      userPayRunDetails.length !== 0 &&
      updateDeductionStates
    ) {
      let companyDeductionDetails = deductionData;
      let defaultDeductionWithAction = userPayRunDetails?.deductions?.map(
        (element) => {
          return {
            ...element,
            action: "default",
          };
        }
      );
      let additionalDeductionsWithAction =
        userPayRunDetails?.additional_deductions?.map((element) => {
          return {
            ...element,
            action: "additional",
          };
        });
      let allAddedDeductions = [
        ...defaultDeductionWithAction,
        ...additionalDeductionsWithAction,
      ];
      let max = 0;
      allAddedDeductions?.forEach((character) => {
        if (character.id > max) {
          max = character.id;
        }
      });
      deductionId = max;
      setAllDeductionDetails(allAddedDeductions);
      allAddedDeductions.forEach((element) => {
        companyDeductionDetails = companyDeductionDetails?.filter((data) => {
          return data.name !== element.name;
        });
      });
      let totalEarningAmount = 0;
      allAddedDeductions.forEach((element) => {
        totalEarningAmount =
          totalEarningAmount +
          parseFloat(element.amount === "" ? 0 : element.amount);
      });
      setDeductionTotalAmount(totalEarningAmount);
      setCompanyRemainingDeductionDetails(companyDeductionDetails);
      setUpdateDeductionStates(false);
    }
  }, [
    deductionData,
    loadDeductions,
    updateDeductionStates,
    userPayRunDetails?.additional_deductions,
    userPayRunDetails?.deductions,
    userPayRunDetails.length,
  ]);

  const AddDeduction = async () => {
    let newDeductionData = {
      id: deductionId + 1,
      name: "",
      amount: 0,
      action: "add",
    };
    deductionId = deductionId + 1;
    setAllDeductionDetails([...allDeductionDetails, newDeductionData]);
  };

  const updateDeductionAmount = (id) => (event) => {
    if (numberWithDecimalRegex.test(event.target.value)) {
      let updateSalaryComponent = allDeductionDetails?.map((element) => {
        if (element.id === id) {
          if (element.action === "additional") {
            return {
              ...element,
              amount: event.target.value,
              action: "update",
            };
          } else {
            return {
              ...element,
              amount: event.target.value,
            };
          }
        } else {
          return element;
        }
      });
      setAllDeductionDetails(updateSalaryComponent);
      let totalAmount = 0;
      updateSalaryComponent.forEach((element) => {
        if (element.action !== "delete") {
          totalAmount =
            totalAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setDeductionTotalAmount(totalAmount);
    }
  };

  const updateDeductionName = (data, id) => {
    let updateSalaryComponent = allDeductionDetails?.map((element) => {
      if (element.id === id) {
        return {
          ...element,
          name: data?.name,
          amount: data?.fixed_amount,
        };
      } else {
        return element;
      }
    });
    setAllDeductionDetails(updateSalaryComponent);
    let totalAmount = 0;
    updateSalaryComponent.forEach((element) => {
      if (element.action !== "delete") {
        totalAmount =
          totalAmount + parseFloat(element.amount === "" ? 0 : element.amount);
      }
    });
    setDeductionTotalAmount(totalAmount);
  };

  const onBlurToRemoveAlreadyUsedDeductions = () => {
    let companyDeductionDetails = deductionData;
    allDeductionDetails.forEach((element) => {
      companyDeductionDetails = companyDeductionDetails.filter((data) => {
        return data.name !== element.name || element.action === "delete";
      });
    });
    setCompanyRemainingDeductionDetails(companyDeductionDetails);
  };

  const removeAditionalDeductions = (data) => {
    if (data.action === "update" || data.action === "additional") {
      let updateSalaryComponent = allDeductionDetails?.map((element) => {
        if (element.id === data.id) {
          return {
            ...element,
            action: "delete",
          };
        } else {
          return element;
        }
      });
      setAllDeductionDetails(updateSalaryComponent);
      let companyDeductionDetails = deductionData;
      updateSalaryComponent.forEach((element) => {
        companyDeductionDetails = companyDeductionDetails.filter((data) => {
          return data.name !== element.name || element.action !== "delete";
        });
      });
      setCompanyRemainingDeductionDetails(companyDeductionDetails);
      let totalAmount = 0;
      updateSalaryComponent.forEach((element) => {
        if (element.action !== "delete") {
          totalAmount =
            totalAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setDeductionTotalAmount(totalAmount);
    } else {
      let updateDeductionDetails = allDeductionDetails?.filter((fil_data) => {
        return fil_data.name !== data.name;
      });
      setAllDeductionDetails(updateDeductionDetails);
      let companyDeductionDetails = deductionData;
      updateDeductionDetails.forEach((element) => {
        companyDeductionDetails = companyDeductionDetails.filter((data) => {
          return data.name !== element.name || element.action !== "delete";
        });
      });
      setCompanyRemainingDeductionDetails(companyDeductionDetails);
      let totalEarningAmount = 0;
      updateDeductionDetails.forEach((element) => {
        if (element.action !== "delete") {
          totalEarningAmount =
            totalEarningAmount +
            parseFloat(element.amount === "" ? 0 : element.amount);
        }
      });
      setDeductionTotalAmount(totalEarningAmount);
    }
  };

  //update with api
  const submitChanges = async (action) => {
    if (lopError !== "LOP days exceed the number of working shifts") {
      let remainingDeduction = allDeductionDetails.filter((data) => {
        return (
          data.amount !== "" &&
          data.amount !== "0" &&
          data.amount !== 0 &&
          data.name !== ""
        );
      });
      let remainingEarning = allEarningDetails.filter((data) => {
        return (
          data.amount !== "" &&
          data.amount !== "0" &&
          data.amount !== 0 &&
          data.name !== ""
        );
      });
      try {
        const response = await fetch(`${domain}payrun/edit/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            payroll_run_id: editUserDetails.id,
            additional_deductions: remainingDeduction,
            lop_adjustments: [
              {
                days: lopDays === "" ? 0 : lopDays,
                reason: "Approved LOP for personal reasons",
              },
            ],
            additional_earnings: remainingEarning,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          showToast("success", res.message);
          reloadApi(true);
          actionButtonBasedOnStatusWithInitiate();
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    }
  };

  const actionButtonBasedOnStatusWithInitiate = async () => {
    try {
      const response = await fetch(`${domain}payrun/initiate/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          pay_schedule_id: viewPayrunDetails.id,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        setAllEarningDetails([]);
        setAllDeductionDetails([]);
        reCallApi(true);
        setTimeout(function () {
          setUpdatedData(true);
          setUpdateDeductionStates(true);
          setEarningStateUpdate(true);
        }, 400);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  return (
    <React.Fragment>
      <Mui.Drawer
        anchor="right"
        open={openSidePeek}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          sx: {
            width: {
              xs: 300,
              sm: 360,
              md: 500,
              lg: 700,
            },
          },
        }}
      >
        {!loading ? (
          <>
            <Mui.Grid container sx={{ paddingTop: 3 }}>
              <Mui.Grid
                container
                sx={{
                  paddingLeft: 1,
                  paddingTop: 1,
                  backgroundColor: themes.formBackground,
                  paddingBottom: 1,
                }}
              >
                <Mui.Grid xs={1}>
                  <Mui.Avatar
                    sx={{ width: 60, height: 60 }}
                    alt="Remy Sharp"
                    src={editUserDetails.image_url}
                  />
                </Mui.Grid>
                <Mui.Grid xs={5} sx={{ paddingLeft: 2 }}>
                  <Mui.Typography sx={{ color: themes.headLine }}>
                    {editUserDetails.user_name}
                  </Mui.Typography>
                  <Mui.Typography sx={{ color: themes.shadowText }}>
                    Emp.ID : {editUserDetails.employee_id}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4}>
                  <Mui.Typography sx={{ color: themes.shadowText }}>
                    Net Pay
                  </Mui.Typography>
                  <Mui.Typography sx={{ color: themes.shadowText }}>
                    {userPayRunDetails?.net_pay}{" "}
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={2} sx={{ paddingTop: 1.5 }}>
                  <Mui.IconButton
                    sx={{
                      backgroundColor: themes.blackColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                      },
                    }}
                    onClick={() => setOpenSidePeek(false)}
                  >
                    <Close sx={{ color: themes.whiteColor }} />
                  </Mui.IconButton>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{ paddingTop: 2, paddingLeft: 2, paddingBottom: 1 }}
              >
                <Mui.Grid xs={8}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "18",
                    }}
                  >
                    No. of shifts during the period
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4}>{payrunData?.working_days}</Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingLeft: 2, paddingBottom: 1 }}>
                <Mui.Grid xs={8}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "18",
                    }}
                  >
                    No. of shifts attended
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4}>
                  {userPayRunDetails?.actual_days_to_be_paid}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 1 }}
              >
                <Mui.Grid xs={8}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "18",
                    }}
                  >
                    Additional LOP shifts
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4} sx={{ paddingRight: 1 }}>
                  {viewPayrunDetails.status !== "Rejected" &&
                  viewPayrunDetails.status !== "Approved" ? (
                    <Mui.TextField
                      size="small"
                      value={lopDays}
                      sx={{ width: 200 }}
                      onChange={(event) => {
                        if (
                          (onlyNumberRegex.test(event.target.value) &&
                            parseInt(
                              userPayRunDetails?.actual_days_to_be_paid
                            ) >= parseInt(event.target.value)) ||
                          event.target.value === ""
                        ) {
                          setLopDays(event.target.value);
                          setLopError("");
                        } else if (
                          parseInt(userPayRunDetails?.actual_days_to_be_paid) <
                          parseInt(event.target.value)
                        ) {
                          setLopDays(event.target.value);
                          setLopError(
                            "LOP days exceed the number of working shifts"
                          );
                        }
                      }}
                      onBlur={() => {
                        setPaidDays(
                          userPayRunDetails?.actual_days_to_be_paid - lopDays
                        );
                      }}
                      error={
                        lopError ===
                        "LOP days exceed the number of working shifts"
                          ? true
                          : false
                      }
                      helperText={
                        lopError ===
                        "LOP days exceed the number of working shifts"
                          ? lopError
                          : ""
                      }
                    />
                  ) : (
                    lopDays
                  )}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Grid
                container
                sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 1 }}
              >
                <Mui.Grid xs={8}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "18",
                    }}
                  >
                    Payable shifts
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4} sx={{ paddingRight: 1 }}>
                  {paidDays}
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider />
              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  paddingLeft: 2,
                  paddingBottom: 2,
                  backgroundColor: themes.formBackground,
                }}
              >
                <Mui.Grid xs={6}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    +Earnings
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    Amount
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={2}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    Actions
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider />
              {allEarningDetails?.map((earning) =>
                earning.action === "add" ||
                earning.action === "additional" ||
                earning.action === "update" ? (
                  <Mui.Grid container sx={{ paddingTop: 1, paddingLeft: 3 }}>
                    <Mui.Grid xs={6} sx={{ paddingRight: 10 }}>
                      {earning.action === "additional" ||
                      earning.action === "update" ? (
                        <Mui.TextField
                          defaultValue={earning.name}
                          size="small"
                          sx={{ width: "100%" }}
                        />
                      ) : (
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={earning.name}
                          options={companyRemainingEarnings?.map((obj) => ({
                            ...obj,
                            label: obj.name,
                          }))}
                          sx={{ width: "100%" }}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return (
                              option.label !== "Special Allowance" &&
                              option.label !== "Basic" && (
                                <li {...props}>{option.label}</li>
                              )
                            );
                          }}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              updateEarningName(value, earning.id);
                            }
                          }}
                          onBlur={onBlurToRemoveAlreadyUsedEarnings}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ background: themes.whiteColor }}
                              placeholder="Please select the salary component"
                              size="small"
                              value={earning.name}
                              disabled={
                                earning.action === "additional" ||
                                earning.action === "update"
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>
                        <CustomTextBox
                          selectedValue={earning.amount}
                          updateDataFunction={updateEarningAmount(earning.id)}
                          placeHolder="Enter the amount"
                        />
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={2}>
                      <Mui.IconButton>
                        <Close
                          onClick={() => removeAditionalEarning(earning)}
                        />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                ) : earning.action !== "delete" ? (
                  <Mui.Grid container sx={{ paddingTop: 1, paddingLeft: 3 }}>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{earning.name}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{earning.amount}</Mui.Typography>
                    </Mui.Grid>
                  </Mui.Grid>
                ) : null
              )}
              <Mui.Grid container sx={{ paddingTop: 1 }} />
              <Mui.Grid
                container
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  borderTop: `1px solid ${themes.shadowText}`,
                  borderBottom: `1px solid ${themes.shadowText}`,
                }}
              >
                <Mui.Grid xs={6} sx={{ paddingLeft: 3 }}>
                  Total
                </Mui.Grid>
                <Mui.Grid xs={4} sx={{ paddingLeft: 1 }}>
                  {Math.round(earningTotalAmount)}
                </Mui.Grid>
                <Mui.Grid xs={2} sx={{ paddingLeft: 1 }}></Mui.Grid>
              </Mui.Grid>
              {viewPayrunDetails.status !== "Rejected" &&
              viewPayrunDetails.status !== "Approved" ? (
                <Mui.Grid container sx={{ paddingLeft: 1 }}>
                  <Mui.IconButton
                    onClick={() => AddEarning()}
                    disabled={
                      companyRemainingEarnings?.length === 0 ? true : false
                    }
                  >
                    <Mui.Tooltip title="Add Earning">
                      <AddCircleOutline />
                    </Mui.Tooltip>
                  </Mui.IconButton>
                </Mui.Grid>
              ) : null}
              <Mui.Divider />
              <Mui.Grid container sx={{ paddingTop: 1 }}></Mui.Grid>
              <Mui.Grid
                container
                sx={{
                  paddingTop: 2,
                  paddingLeft: 3,
                  paddingBottom: 2,
                  backgroundColor: themes.formBackground,
                }}
              >
                <Mui.Grid xs={6}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    -Deductions
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={4}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    Amount
                  </Mui.Typography>
                </Mui.Grid>
                <Mui.Grid xs={2}>
                  <Mui.Typography
                    sx={{
                      color: themes.shadowText,
                      fontWeight: "bold",
                      fontSize: "22",
                    }}
                  >
                    Actions
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.Divider />
              {allDeductionDetails?.map((deduct) =>
                deduct.action === "add" ||
                deduct.action === "additional" ||
                deduct.action === "update" ? (
                  <Mui.Grid container sx={{ paddingTop: 1, paddingLeft: 3 }}>
                    <Mui.Grid xs={6} sx={{ paddingRight: 10 }}>
                      {deduct.action === "additional" ||
                      deduct.action === "update" ? (
                        <Mui.TextField
                          defaultValue={deduct.name}
                          size="small"
                        />
                      ) : (
                        <Mui.Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          size="small"
                          value={deduct.name}
                          options={companyRemainingDeductionDetails?.map(
                            (obj) => ({
                              ...obj,
                              label: obj.name,
                            })
                          )}
                          sx={{ width: "100%" }}
                          filterOptions={(options, state) => {
                            return options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(state.inputValue.toLowerCase())
                            );
                          }}
                          renderOption={(props, option) => {
                            return (
                              option.label !== "Special Allowance" &&
                              option.label !== "Basic" && (
                                <li {...props}>{option.label}</li>
                              )
                            );
                          }}
                          onBlur={onBlurToRemoveAlreadyUsedDeductions}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                            },
                          }}
                          onChange={(event, value) => {
                            if (value) {
                              updateDeductionName(value, deduct.id);
                            }
                          }}
                          renderInput={(params) => (
                            <Mui.TextField
                              {...params}
                              sx={{ background: themes.whiteColor }}
                              placeholder="Please select the salary component"
                              size="small"
                              value={deduct.name}
                              onBlur={onBlurToRemoveAlreadyUsedDeductions}
                              disabled={
                                deduct.action === "additional" ||
                                deduct.action === "update"
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>
                        <CustomTextBox
                          selectedValue={deduct.amount}
                          updateDataFunction={updateDeductionAmount(deduct.id)}
                          placeHolder="Enter the amount"
                        />
                      </Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={2}>
                      <Mui.IconButton
                        onClick={() => removeAditionalDeductions(deduct)}
                      >
                        <Close />
                      </Mui.IconButton>
                    </Mui.Grid>
                  </Mui.Grid>
                ) : deduct.action !== "delete" ? (
                  <Mui.Grid container sx={{ paddingTop: 1, paddingLeft: 3 }}>
                    <Mui.Grid xs={6}>
                      <Mui.Typography>{deduct.name}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={4}>
                      <Mui.Typography>{deduct.amount}</Mui.Typography>
                    </Mui.Grid>
                    <Mui.Grid xs={2}></Mui.Grid>
                  </Mui.Grid>
                ) : null
              )}
              <Mui.Grid container sx={{ paddingTop: 1 }} />

              <Mui.Grid
                container
                sx={{
                  paddingTop: 1,
                  paddingBottom: 1,
                  borderTop: `1px solid ${themes.shadowText}`,
                  borderBottom: `1px solid ${themes.shadowText}`,
                }}
              >
                <Mui.Grid xs={6} sx={{ paddingLeft: 3 }}>
                  Total
                </Mui.Grid>
                <Mui.Grid xs={4} sx={{ paddingLeft: 1 }}>
                  {Math.round(deductionTotalAmount.toFixed(2))}
                </Mui.Grid>
                <Mui.Grid xs={2} sx={{ paddingLeft: 1 }}></Mui.Grid>
              </Mui.Grid>
              <Mui.Grid container sx={{ paddingTop: 1 }}></Mui.Grid>
              {viewPayrunDetails.status !== "Rejected" &&
              viewPayrunDetails.status !== "Approved" ? (
                <Mui.Grid container sx={{ paddingLeft: 2 }}>
                  <Mui.IconButton
                    onClick={() => AddDeduction()}
                    disabled={
                      companyRemainingDeductionDetails?.length === 0
                        ? true
                        : false
                    }
                  >
                    <Mui.Tooltip title="Add Deduction">
                      <AddCircleOutline />
                    </Mui.Tooltip>
                  </Mui.IconButton>
                </Mui.Grid>
              ) : null}
            </Mui.Grid>
            {viewPayrunDetails.status !== "Rejected" &&
            viewPayrunDetails.status !== "Approved" ? (
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 1,
                }}
              >
                <CustomButton
                  actionFuntion={() => submitChanges("SaveButton")}
                  actionName="Re-calculate"
                  typeName="button"
                />
              </Mui.Grid>
            ) : null}
            <Mui.Grid container sx={{ paddingTop: 2 }}></Mui.Grid>
          </>
        ) : (
          <Mui.Grid container alignItems="center" sx={{ paddingTop: 10 }}>
            <Loader info="Loading..." />
          </Mui.Grid>
        )}
      </Mui.Drawer>
    </React.Fragment>
  );
};
